import React from 'react';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import {Interface} from '../../components/assets';

function StayinTouch(){
  return(
    <Container fluid className="bg-white text-center py-5 get-in-touch">
      <Container>
        <Row className="my-5">
          <Col>
            <img src={Interface} alt="Interface" className="mb-3" />
            <h3 className="mb-0">Couldn't find a perfect fit? Don't worry. Tell us about yourself and what you’re interested in.</h3>
            <h3 className="mt-0">We always want to hear from good people who want to make an impact.</h3>
            <a href="mailto:career@complitech.net">Stay in Touch</a>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default StayinTouch;