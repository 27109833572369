import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import ProductivityPosts from '../../pages/blogs/blogCategory';
import SectionTitle from '../../components/sectionTitle';

function Productivity(){
  return(
      <div>
        <Container fluid className="bg-light">
          <Container className="pt-105">
            <Row>
              <Col md="12">
                <SectionTitle title="Productivity" color="black" />
              </Col>
            </Row>

            <Row>
              <Col md="12" className="py-5">
                <ProductivityPosts category="productivity" />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    );
  }
  export default Productivity;