import React from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import SectionTitle, {SectionSubTitle} from '../../../components/sectionTitle';
import { Title, Description } from '../vacancyComponents';

import VacancyDetail from '../vacancyDetail';
import VacancyForm from '../vacancyForm';

import ToolsPost from '../toolsPost';

import{
  AndroidDeveloperBG
} from '../../../components/assets'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCaretRight, faLongArrowAltLeft } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faLongArrowAltLeft, faCaretRight)

function AndroidDeveloper(props){
  return(
    <>
      <Container>
        {/* Job Title And Description */}
        <Row className="pt-105 pb-5">
          <Col md="12">
            <NavLink to="/careers#currentOpenings" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              Back to all open positions
            </NavLink>
          </Col>
          <Col md="12">
            <SectionTitle title="Android Developer" color="black" />
            <SectionSubTitle title="If you would like to be working on building and enhancing native Android apps, monitoring and optimising existing apps, as well as further develop your own skills, this is a job for you!" color="black" className="mt-4" />
          </Col>
        </Row>

        <Row>
          <Col md="6" lg="3" className="mb-3 mb-lg-0">
            <Title title="Job location:" />
            <Description title="Ahmedabad"/>
          </Col>

          <Col md="6" lg="3" className="mb-3 mb-lg-0">
            <Title title="Experience: " />
            <Description title="6+ months - 2 years"/>
          </Col>

          <Col md="6" lg="3" className="mb-3 mb-lg-0">
            <Title title="Job type:" />
            <Description title="Full Time"/>
          </Col>

          <Col md="6" lg="3" className="mb-3 mb-lg-0">
            <Title title="Positions: " />
            <Description title="2"/>
          </Col>
        </Row>

        {/* Application form Link */}
        <Row>
          <Col>
            <Link to="vacancy-form" smooth={true} offset={-70} duration={500}>
              <SectionSubTitle title={["Apply for this position ", <FontAwesomeIcon icon="caret-right" className="ml-2" key="1"/>]} className="py-5 d-flex align-items-center color-green"></SectionSubTitle>
            </Link>
          </Col>
        </Row>

        {/* Banner */}
        <Row>
          <Col>
            <img src={AndroidDeveloperBG} alt="Android Developers" className="img-fluid" />
          </Col>
        </Row>

        {/* Our Requirements */}
        <Row>
          <VacancyDetail
            id="android"
            keyResponsibility={[
              <li key="1">Developing/customizing Android applications.</li>,
              <li key="2">Ongoing development of existing Android projects.</li>,
              <li key="3">Working with backend developers for API requirements in the apps.</li>,
              <li key="4">Submitting Android applications to clients for review and Google Play Store.</li>,
            ]}

            skills={[
              <li key="1">6+ months of experience in Android development.</li>,
              <li key="2">Android Studio expertise.</li>,
              <li key="3">REST API integration / JSON understanding.</li>,
              <li key="4">GPS Tracking, Bluetooth integration with apps.</li>,
              <li key="5">FireBase / AWS integration.</li>,
            ]}
          />
        </Row>

        {/* Tools we Use */}
        <Row className="py-5 toolsUse">
          <Col md="12" className="mb-5">
            <SectionTitle title="Tools we use" />
          </Col>

          <ToolsPost />
        </Row>

        <Row id="vacancy-form">
          <VacancyForm vacancy={"Android Developer"}/>
        </Row>
      </Container>

      {/*<Container fluid className="bg-primary text-center py-5 get-in-touch">
        <Container>
          <Row className="my-5">
            <Col md="12">
              <img src={BriefCase} alt="Briefcase" />
            </Col>
            <Col md="12" className="mt-4">
              <NavLink to="/careers">View all openings</NavLink>
            </Col>
          </Row>
        </Container>
      </Container>*/}
    </>
  );
}

export default AndroidDeveloper;
