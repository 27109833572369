import React from 'react';

import {Container} from 'reactstrap';

import WorkPosts from './workCategory';

const WorkPost = (props) => {
  return (
    <Container fluid className="py-5 bg-light" id="allWork">
      <WorkPosts />
    </Container>
  );
};

export default WorkPost;