import React from 'react'

function Title(props){
  return(
    <h1 className={props.className}>{props.title}</h1>
  );
}

function TitleSmall(props){
  return(
    <h2 className={props.color}>{props.title}</h2>
  );
}

export default Title;

export {TitleSmall};