import React from 'react'

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

import {
  CSTriviagoBG,
  Statistics,
  PlatformRails,
  PlatformCrash,
  PlatformKotlin,
  PlatformMixpanel,
  PlatformSwift,
  PlatformZeplin
} from '../../components/assets'

import {NavLink} from 'react-router-dom';

import SectionTitle from '../../components/sectionTitle';

import { LargeText } from '../../components/projectTitle';

import GetinTouch from '../../components/getinTouch';

function CSTriviago() {
  return(
    <Container fluid >
      <Row className="bg-white pt-105 pb-3"></Row>
      
      <Container className="py-5">
        <Row>
          <Col md="12">
            <SectionTitle title="TriviaGo" color="black"/>
            
            <p>The Client, a US company, that produces and advertises digital solutions for local businesses, asked us to collaborate on a trivia game concept. They wanted a scalable solution that can start from a small number of participating bars/restaurants, and can be pitched to businesses located in other states of the USA. The client wanted to confine the app release to Christmas for best sales results and we had exactly 4.5 months to build the solution.</p>
          </Col>

          <Col md="12">
            <div className="p-4 bg-light">
              <h3 className="mb-0">The solution would enable customer engagement, which in turn can result in higher revenue for the bars/restaurants. Client also wanted us to build a powerful and flexible platform that can ac-commodate thousands of users playing trivia games.</h3>
            </div>
          </Col>

          <Col md="12" className="mt-5">
            <SectionTitle title="Our Role" />

            <p>The client had designed some UX when they approached us. We reviewed and had provided them some ideas to make the UX even better, beautiful and intuitive. The client was delighted with our suggestions. They assigned us with Ul/UX responsibility in addition to development.</p>

            <p>While we were collaborating for Ul/UX, our backend dev team worked on putting together the database schema as well as API collection needed by the mobile team. So, finally, we had been all set to put to-gether all the puzzle pieces and make sure what we have produced works well. Not just our QA team, but mobile and backend developers loved testing the trivia app, so does the client and some beta testers.</p>
          </Col>
        </Row>
      </Container>

      <Row>
        <img src={CSTriviagoBG} alt="Background" className="img-fluid w-100" />
      </Row>

      <Container className="py-5">
        <Row>
          <Col md="5" className="bg-black p-4 p-lg-5">
            <SectionTitle title="Challenges" color="white mb-4" />
            <LargeText title="Among the several challenges we had, the major one was to keep game, user, coins, points, etc. data in sync with the app and backend server that too in real time. Another one was that the user or game shouldn't be affected by unstable Internet connectivity. Lastly, apart from the powerful merchant panel, we had to build a robust admin panel." color="white" />
          </Col>

          <Col md="7" className="bg-light p-4 p-lg-5">
            <SectionTitle title="Implementation" color="black mb-4" />
            <LargeText title="In order to provide real time experience, we used Web Sockets as the underlying technology providing a two way instant update mechanism. And, that also took care of shakey internet connectivity." />

            <LargeText title="Moreover, we developed our pride, a truly powerful administrator panel. Besides the content and data management, an admin can view how things are working out through the data charts on Admin Dashboard." />
          </Col>
        </Row>
      </Container>

      <Container>
        <img src={Statistics} alt="Statistics" className="img-fluid w-100" />
      </Container>

      <Container className="py-5">
        <SectionTitle title="Key Features" color="black" />
        <Row className="mt-4">
          <Col md="5">
            <ol>
              <li><p className="mb-0">Quiz Quest</p></li>
              <li><p className="mb-0">Check-in to restaurants and bars</p></li>
              <li><p className="mb-0">Play Solo & Tournament Games</p></li>
              <li><p className="mb-0">Redeem Coins</p></li>
              <li><p className="mb-0">Earn Badges</p></li>
              <li><p className="mb-0">Global/Local Leaderboards</p></li>
            </ol>
          </Col>

          <Col md="7">
            <p>We had to implement a solution that allowed end-users to check in to the bar or restaurant. Then, they can play the game in different modes, like solo, tournament, quiz quest, etc. By playing the game, users get to unlock various badges and if they do excellent they get the "Bar Hero" title too. Not just that, users can accumulate coins and redeem them for exciting prizes at the bar or restaurant they're at.</p>
          </Col>
        </Row>

        <Col md="12" className="text-center px-0 mt-2">
          <NavLink to="/work#allWork">
            <Button color="black" size="lg">View Portfolio</Button>
          </NavLink>
        </Col>
      </Container>

      <Row className="py-5 text-center bg-light">
        <Col md="12">
          <SectionTitle title="Built With" />
        </Col>
        <Container className="py-5 technologies-used">
          <Row>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformRails} alt="Rails" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformSwift} alt="Swift" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformKotlin} alt="Kotlin" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformZeplin} alt="Zeplin" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformCrash} alt="Crashlytics" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformMixpanel} alt="MixPanel" className="img-fluid w-auto" /></Col>
          </Row>
        </Container>
      </Row>

      <Row>
        <GetinTouch />
      </Row>
      
    </Container>
  );
}

export default CSTriviago;