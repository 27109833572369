import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap';

function ImageTitleRight(props){
  return(
    <Container fluid>
      <Row className="w-100 mx-0">
        <Container className="py-5">
          <Row className="d-flex align-items-center">
            <Col lg="6">
              <img src={props.Image} alt={props.ImageAlt} className="img-fluid mx-auto d-block" />
            </Col>
            <Col lg="6" className="mt-5 mt-lg-0 text-center text-lg-left">
              <h2 className="section-title">{props.title}</h2>
              <p className="image-desc">{props.description}</p>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

function ImageTitleLeft(props){
  return(
    <Container fluid className="bg-light">
      <Row className="w-100 mx-0">
        <Container className="py-5">
          <Row className="d-flex align-items-center">
            <Col lg="6" className="order-lg-last">
              <img src={props.Image} alt={props.ImageAlt} className="img-fluid mx-auto d-block" />
            </Col>
            <Col lg="6" className="mt-5 mt-lg-0 text-center text-lg-left">
              <h2 className="section-title">{props.title}</h2>
              <p className="image-desc">{props.description}</p>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export {ImageTitleLeft, ImageTitleRight};