import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import toolsJSON from '../../data/tools.json';

import ToolsCard from './toolsCard';

const toolsCategoryPosts = (props) => {
  const toolsPosts = props.category ? toolsJSON.filter(toolsPost => toolsPost.category === props.category) : toolsJSON;
  
  return (  
    <Row>
      <Container>
        <Row className="">
          {toolsPosts.map((post,index) => (
            <Col md="6" lg="4" className="mb-4 tools cards text-left">
              <ToolsCard
                image={post.image}
                title={post.title}                
                description={post.description}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </Row>
  );
};

export default toolsCategoryPosts;