import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import blogJSON from '../../data/blogs.json';

import BlogCard from './blogCard';

const blogCategoryPosts = (props) => {
  const blogPosts = props.category ? blogJSON.filter(blogPost => blogPost.category === props.category) : blogJSON;

  return (  
      <Row>
        <Container>
          <Row className="blog-post">
            {blogPosts.map((post,index) => (
              <Col md="4" className="mb-4">
                <BlogCard 
                  key={index}
                  category={post.category}
                  title={post.title}
                  url={"/" + post.category + "/" + post.slug}
                  image={post.image}
                  readtime={post.readtime}
                  description={post.description.slice(0,125)+"..."}
                />
              </Col>
            ))}
          </Row>
        </Container>
      </Row>
  );
};

export default blogCategoryPosts;