import React from 'react'

function WorkPostTitle(props){
  return(
    <h2 className="section-subtitle text-decoration-none">{props.title}</h2>
  );
}

function WorkPostText(props){
  return(
    <p className={['text-decoration-none mb-1 ' + props.className ]}>{props.title}</p>
  );
}

export default WorkPostTitle;
export {WorkPostText};