import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {henslerImage} from '../../components/assets'

const Invoease = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>	

	    	<Row>
	    		<Col md="8">
	    			<img src={henslerImage} alt="Hensler Surgical" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="Hensler Surgical" />
		    			<WorkPostText className="mt-2 mb-3" title="This App is the OFFICIAL resource for Hensler Surgical Technologies. From our flagship product, the Hensler Bone Press, which is becoming the standard in Autograft harvesting from high-speed drill utilizing surgeons, to our premier single-use disposable bipolars. Intra-operative case presentations, case videos, tips/pearls and a newly integrated Hensler Surgical representative back-office platform is all at your finger tips. Designed for Surgeons, distributors/representatives and of course whom we consider all our products benefitting ... our Patients." />
		    			<a href="https://apps.apple.com/az/app/hensler-surgical-technologies/id1484595501?ign-mpt=uo%3D2" target="blank">
                <Button color="black" size="lg" className="mb-3">Check out on App Store</Button>
              </a>
		    			<a href="https://play.google.com/store/apps/details?id=proclaim.hensler&hl=en_CA" target="blank">
                <Button color="black" size="lg" className="mb-3">Check out on Play Store</Button>
              </a>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default Invoease;