import React from 'react';

import PageBanner from '../../components/pageBanner';
import Mission from './mission';
import Values from './values';
import ReadytoRock from './readytoRock';
// import DevelopApp from './developApp';
// import Testimonial from '../common/testimonial';
import GetinTouch from '../../components/getinTouch';

import {AboutBannerBG} from '../../components/assets';

function About(){
  return(
      <div>
        <PageBanner 
          bannerImage={AboutBannerBG} 
          bannerImageAlt="About Complitech" 
          title={<>We're creative ninjas, We're <span className="highlight purple" key="1">Complitech</span></>}
          description="We’re a team of curious people that share a passion for creating digital experiences that generate greater results."
        />
        <Mission />
        <Values />
        <ReadytoRock />
        <GetinTouch />
      </div>
    );
  }
  export default About;