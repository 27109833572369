import React from 'react';
import{
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import { NavLink } from 'react-router-dom'

import PageBanner from './pageBanner';

import {Error404} from './assets';

function Error(){
  return(
    <Container fluid className="text-left">
        <PageBanner 
          bannerImage={Error404}
          bannerImageAlt="Error 404 - we couldn't find that page"
          title={<><span className="highlight red">Ooops</span>... we couldn't find that page!</>}
          description={<>We may have missed to create that page or have been removed.<br/><br/> Do checkout our other awesome pages.
          <Row className="mx-0">
            <Col className="px-0 mt-4">
                <NavLink to="/work">
                <Button color="transparent" className="btn btn-outline mr-1" size="sm">Our Work</Button>
                </NavLink>
                <NavLink to="/service">
                <Button color="transparent" className="btn btn-outline mr-1" size="sm">What We Offer</Button>
                </NavLink>
                <NavLink to="/about">
                <Button color="transparent" className="btn btn-outline" size="sm">About Complitech</Button>
                </NavLink>
            </Col>
          </Row></>}
        />
    </Container>
  );
}
export default Error;