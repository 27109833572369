import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import TechnicalPosts from '../../pages/blogs/blogCategory';
import SectionTitle from '../../components/sectionTitle';

function Technical(){
  return(
  <div>
    <Container fluid className="bg-light">
      <Container className="pt-105">
        <Row>
          <Col md="12">
            <SectionTitle title="Technical" color="black" />
          </Col>
        </Row>

        <Row>
          <Col md="12" className="py-5">
            <TechnicalPosts category="technical" />
          </Col>
        </Row>
      </Container>
    </Container>
  </div>
);
}
export default Technical;