import React from 'react';

import { NavLink } from 'react-router-dom';

import {
  Card,
  CardImg,
  CardBody,
} from 'reactstrap';

import BlogPostTitle, {BlogPostDate, BlogPostText} from './blogPostTags'


export default (props) => {
  return(
    <NavLink to={props.url || "#"}>
      <Card>
        <CardImg
          top
          src={ require('../../assets/images/blogs/'+props.image) }
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title={props.title} />
          <BlogPostDate title={props.category + " • " + props.readtime + " min read"} />
          <BlogPostText title={props.description} />
        </CardBody>

      </Card>
    </NavLink>
  );
}