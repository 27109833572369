import React from 'react'
import { NavLink } from 'react-router-dom';
import { LargeText } from '../../components/projectTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faLongArrowAltLeft, faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

library.add(fab, faLongArrowAltLeft, faLongArrowAltRight)

function BlogPostTitle(props){
  return(
    <h4 className={"blogpost-title " + props.className || ""}>{props.title}</h4>
  );
}

function BlogPostDate(props){
  return(
    <h6 className="blogpost-date">{props.title}</h6>
  );
}

function BlogPostText(props){
  return(
    <p className="blogpost-text mb-0">{props.title}</p>
  );
}

function BlogQuote(props){
  return(
    <p className={"blog-quote " + props.size || "lg"}>{props.title}</p>
  );
}

function BlogHashTags(props){
  return(
    <p className="text-dark bg-light rounded d-inline-block p-2 mr-3">{props.title}</p>
  );
}

function PrevBlog(props){
  return(
    <NavLink to={props.to}>
      <FontAwesomeIcon icon="long-arrow-alt-left" /> Prev
      <LargeText title={props.title} className="mt-3 mb-0" color="black" />
    </NavLink>
  );
}

function NextBlog(props){
  return(
    <NavLink to={props.to}>
      Next <FontAwesomeIcon icon="long-arrow-alt-right" />
      <LargeText title={props.title} className="mt-3 mb-0" color="black" />
    </NavLink>
  );
}

export default BlogPostTitle;
export {BlogPostDate, BlogPostText, BlogQuote, BlogHashTags, PrevBlog, NextBlog};