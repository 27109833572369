import React, { Component } from 'react';

import {
	Container,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
  Input,
  Label,
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle';
import { LargeText } from '../../components/projectTitle';

const initial_state  = {
  name: "",
  email: "",
  phone: "",
  aboutYou: "",
  projectDetail: "",
  deadline: "",
  project_budget: "",
  targetAudience: "",
  additionalInfo: "",
  interested_services: [],
  formvalid: false,
  email_error: "",
  name_error: "",
  phone_error: "",
  aboutYou_error: "",
  projectDetail_error: "",
  deadline_error: "",
  project_budget_error: "",
  targetAudience_error: "",
  interested_services_error: ""
}

export default class HireusForm extends Component {

  constructor(props){
    super(props);
    this.state = initial_state;
  }

  updateUser = (event) => {
    const {name, value} = event.target;
    this.setState({
      ...this.state,
      [name]: value
    })
  }

  getChecked = (event) => {
    const {name, value} = event.target;
    var target = event.target
    if(target.checked){
      this.state.interested_services.push(value)
    }
  }

	submitHandler = (event) => {
		event.preventDefault();
	 	let formIsValid = this.checkFormValidation()
    console.log(formIsValid);
    console.log(this.state);
    
	 	if (formIsValid){
		 this.submitForm()
	 	}
	}

  submitForm = () => {
    console.log(this.state);
  }

  checkFormValidation = () => {
    let email_error = "";
    let name_error = "";
    let phone_error = "";


    if (this.state.email === ""){
      email_error = "Email can't be blank!"
    }else if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.email)))
    {
      email_error = "Invalid email!"
    }
    if (this.state.name === ""){
      name_error = "Name can't be blank!"
    }
    if (this.state.phone === ""){
      phone_error = "Phone number can't be blank!"
    }else if (isNaN(this.state.phone)) {
      phone_error = "Phone should be Numeric"
    }else if (this.state.phone.length < 10){
      phone_error = "Phone number length is small."
    }else if (this.state.phone.length > 10) {
      phone_error = "Phone number length is too large."
    }

    this.setState({
      email_error,
      name_error,
      phone_error
    })

    if(name_error !== "" || email_error !== "" || phone_error !== "" ){  
      return false
    }else{
      return true
    }

  }

	addErrorMessage = (msg) => {
		if (msg === ""){
			return ;
		}else{
			return (<span className="error-class"> {msg} </span>);
		}
  }
  
  checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
      ? event.which
      : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }


  render(){
  return(
    <Form id="hire-us-form">
      <Container fluid className="light-bg py-5">
        <Container className="text-left mt-4">
          <SectionTitle title="A Little About You" />
          <LargeText className="mt-4 mb-0" title="With our potential and explicit pricing model, we will entertain your inquiry in the best possible way to give an adequate reply." />

          <Row className="mt-5">
            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="name" name="name" placeholder="Name" onChange={this.updateUser}/>
                {this.addErrorMessage(this.state.name_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="email" id="email" name="email" placeholder="Email" onChange={this.updateUser}/>
                {this.addErrorMessage(this.state.email_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="tel" id="phone" name="phone" placeholder="Phone" onChange={this.updateUser} maxLength="10" onKeyPress={this.checkNumeric}/>
                {this.addErrorMessage(this.state.phone_error)}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="textarea" id="aboutYou" name="aboutYou" onChange={this.updateUser} placeholder="About You / Your Company" />
              </FormGroup>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="py-5">
        <Container className="text-left">
          <SectionTitle title="About Your Project"/>

          <Row className="mt-5">
            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="projectDetail" onChange={this.updateUser} name="projectDetail" placeholder="Tell us about your Project" />
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="deadline" name="deadline" onChange={this.updateUser} placeholder="What are your Deadline?" />
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Label for="budget">How much is your Budget?</Label>
                <Input type="select" name="project_budget" id="budget" onChange={this.updateUser} >
                  <option value="$5000 to $10000">$5000 to $10000</option>
                  <option value="$10000 to $25000">$10000 to $25000</option>
                  <option value="$25000 to $50000">$25000 to $50000</option>
                  <option value="Over $50000">Over $50000</option>
                </Input>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="targetAudience" name="targetAudience" onChange={this.updateUser} placeholder="Who is your target Audience" />
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="textarea" id="additionalInfo" name="additionalInfo" onChange={this.updateUser} placeholder="please provide any additional information that could help us"/>
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Label className="pb-0">Services Interested in</Label>
                <Row>
                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="iOS (iPhone / iPad) Apps"  onChange={this.getChecked}/>{' '}
                        iOS (iPhone / iPad) Apps
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="Android Apps" onChange={this.getChecked} />{' '}
                        Android Apps
                      </Label>
                    </FormGroup>
                  </Col>
                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="Web / Mobile Design" onChange={this.getChecked}  />{' '}
                        Web / Mobile Design
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="PHP / Wordpress / Laravel" onChange={this.getChecked}/>{' '}
                        PHP / Wordpress / Laravel
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="Ruby on Rails" onChange={this.getChecked} />{' '}
                        Ruby on Rails
                      </Label>
                    </FormGroup>
                  </Col>

                  <Col lg="4" md="6" className="mb-3">
                    <FormGroup check>
                      <Label check>
                        <Input type="checkbox" name="service" value="QA Services" onChange={this.getChecked} />{' '}
                        QA Services
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col className="text-center">
              <Button color="black" size="lg" className="mt-4" onClick={this.submitHandler}>Done. Let's Build your Project </Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Form>
  );
  }
}
