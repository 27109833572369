import React, { useState } from 'react'

import {
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
} from 'reactstrap';

import { NavLink } from 'react-router-dom';

// import Title from '../common/bannerComponents';
import blogJSON from '../../data/blogs.json';

import BlogPostTitle, {BlogPostDate, BlogPostText} from './blogPostTags'

// import {BlogBannerBG} from '../common/assets'
// import {BlogSlide01} from '../common/assets'
import SectionTitle from '../../components/sectionTitle';

// const items = [
//   {
//     image: 'BlogSlide01',
//     altText: 'Slide 1',
//     title: 'Stay focused in a world full of distractions',
//     date: 'April 17, 2020.',
//     text: "In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks. Though working from home is not new for some of them. This can be highly productive, but can also go badly wrong. It's all too easy to get distracted and to get very little done by the end of the day. At home, there are different kinds of distractions, which are not there in a typical workplace."
//   },
//   {
//     image: 'BlogSlide01',
//     altText: 'Slide 2',
//     title: 'Stay focused in a world full of distractions',
//     date: 'April 17, 2020.',
//     text: "In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks. Though working from home is not new for some of them. This can be highly productive, but can also go badly wrong. It's all too easy to get distracted and to get very little done by the end of the day. At home, there are different kinds of distractions, which are not there in a typical workplace."
//   },
//   {
//     image: 'BlogSlide01',
//     altText: 'Slide 3',
//     title: 'Stay focused in a world full of distractions',
//     date: 'April 17, 2020.',
//     text: "In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks. Though working from home is not new for some of them. This can be highly productive, but can also go badly wrong. It's all too easy to get distracted and to get very little done by the end of the day. At home, there are different kinds of distractions, which are not there in a typical workplace."
//   }
// ];

const BlogsBanner = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  }

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  }

  const items = blogJSON.filter(blogPost => blogPost.featured === "true");

  const slides = items.map((item, index) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={index}
      >
        <Row className="blog-slider px-0 pt-3 pt-md-0 mx-0">
          <NavLink to={"/" + item.category + "/" + item.slug}>
            <Row className="mx-0 bg-white">
              <Col md="6" className="px-0">
                <img src={ require('../../assets/images/blogs/'+item.image) } alt={item.title} className="img-fluid"/>
              </Col>

              <Col md="6" className="d-flex align-items-center text-left py-3">
                <Row>
                  <Col md="12" className="px-5">
                    <BlogPostTitle title={item.title} className="font-weight-bold display-5" />
                    <ul className="pl-0">
                      <li className="d-inline-block mr-2"><BlogPostDate title={item.category} /></li>
                      <li className="d-inline-block mr-2 text-secondary">•</li>
                      <li className="d-inline-block"><BlogPostDate title={item.readtime + " min read"} /></li>
                    </ul>
                    <BlogPostText title={item.description.slice(0,125)+"..."} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </NavLink>
        </Row>
      </CarouselItem>
    );
  });
  
  return(
    <>
    {/* <Container fluid className="banner d-flex align-items-center">
      <Row className="w-100 mx-0">
        <Container className="py-5">
          <Row className="d-flex align-items-center">
            <Col xl="12" className="order-xl-last">
              <Title title={[<span key="1">Reading smartens. <span className="highlight purple" key="2">Keep Reading!</span></span>]} />
              <img src={BlogBannerBG} alt="Blog Banner" className="img-fluid"/>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container> */}

    <Container fluid className="banner d-flex align-items-center bg-white py-5">
      <Row className="w-100 mx-0">
        <Container>
          <Row>
            <Col lg="12" className="mt-5 mt-sm-0">
              <SectionTitle title="Fresh Ideas" color="black" />
            </Col>
          </Row>

          <Row className="mx-0 py-5">
            <Carousel
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              className="mx-auto w-100"
            >
              <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} />

              {slides}

              <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
              <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
            </Carousel>
            
          </Row>
        </Container>
      </Row>
    </Container>
    </>
  );
}

export default BlogsBanner;
