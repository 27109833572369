import React from 'react';

function ExpertiseTitle(props){
  return(
    <h2 className="expertise-title">{props.title}</h2>
  );
}

function ExpertiseText(props){
  return(
    <p className="expertise-text1">{props.title}</p>
  );
}

export default ExpertiseTitle;
export {ExpertiseText};