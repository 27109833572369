import React from 'react';

import PageBanner from '../../components/pageBanner';
import ContactForm from './contactForm';

import {ContactBannerBG} from '../../components/assets';

function Contact(){
  return(
      <div>
        <PageBanner 
          bannerImage={ContactBannerBG} 
          bannerImageAlt="Connect with Complitech" 
          title={<>Good gab make <span className="highlight blue" key="1">Good Partnerships</span></>}
          description="There are various simpler ways to connect with us. Got an idea? Talk to us in person. We'll give you our honest opinion."
        />
        <ContactForm />
      </div>
    );
  }
  export default Contact;