import React, { Component } from 'react'

import{
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

import HireUsModal from '../../components/hire_us_modal'

import SectionTitle from '../../components/sectionTitle';
import ProjectTitle from '../../components/projectTitle';

export default class Process extends Component{
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      displayModal : false
    };    
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  triggerredModal = () => {
    const {displayModal} = this.state;
    this.setState({
      displayModal: !displayModal
    })
  }

  render(){
  return(
    <Container className="py-5 mb-5">
      <HireUsModal onRef={(ref) => (this.parentRef = ref)}	parentRef={this.triggerredModal.bind(this)} displayModal={this.state.displayModal} />
      <Row>
        <Col md="6" className="mb-3 mb-md-5">
          <div className="processRight">
            <SectionTitle title="Better Process, Higher Results" />
            <p className="mt-3">We don’t limit ourselves to just delivering a solution. Every step of development is strategized with the aim to provide maximum value to the end users.</p>
          </div>
        </Col>
        <Col md="6" className="mt-2">
          <div className="process pb-3">
            <ProjectTitle title="Discovery" className="mb-3" />
            <h5 className="mt-0">Initial stage of a project where we discuss business analysis and requirements elicitation, technology stack choice, cost estimation and project planning.</h5>
          </div>

          <div className="process pb-1">
            <ProjectTitle title="Design" className="mt-5 mb-3" />
            <h5 className="mt-0">Design is everything. Our design included sophisticated and custom elements to deliver your brand image that attract visitors and help people understand the product, company, and branding.</h5>
          </div>

          <div className="process pb-1">
            <ProjectTitle title="Engineer" className="mt-5 mb-3" />
            <h5 className="mt-0">This phase brings your digital vision to life, from a beautiful design to a custom-coded tested-ok website, we tend to deliver the best in the estimated time.</h5>
          </div>

          <div className="process pb-1">
            <ProjectTitle title="Deliver" className="mt-5 mb-3" />
            <h5 className="mt-0">Endmost phase of the solution where your app/software is ready to release. We offer post-release support to ensure you relentlessly improve your product’s quality and retain your end users.</h5>
          </div>
        </Col>
      </Row>

      <Col className="px-0 mt-5 text-center">
        <Button color="black" size="lg" onClick={this.triggerredModal}>Get Started with Discovery</Button>
      </Col>
    </Container>
  );
}
}
