import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {vitImage} from '../../components/assets'

const Invoease = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>

	    	<Row>
	    		<Col md="8">
	    			<img src={vitImage} alt="Virtual Insurance Tools" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="Virtual Insurance Tools" />
		    			<WorkPostText className="mt-2 mb-3" title="Virtual Insurance Tools was developed by agents for agents to address the technology demands of new generations." />
		    			<WorkPostText className="mt-2 mb-3" title="It is no coincidence that the gap of those who are uninsured or underinsured continues to grow. Quite simply, the insurance industry hasn't kept up." />
		    			<WorkPostText className="mt-2 mb-3" title="Having been in the insurance industry for 25 years and being very technology-driven, we recognize the desire of individuals to be able to conduct business on their terms and that is what Virtual Insurance Tools allows." />
		    			<WorkPostText className="mt-2 mb-3" title="Virtual Insurance Tools was developed by agents for agents to address the technology demands of new generations." />
		    			<WorkPostText className="mt-2 mb-3" title="In short, we have brought the insurance industry to today with relevant, leading edge technology that not only meets the demands of consumers but of agents as well." />
		    			<NavLink to="https://virtualinsurancetools.com/" target="_blank">
                <Button color="black" size="lg" className="mb-3">Check out VIT</Button>
              </NavLink>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default Invoease;