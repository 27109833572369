// Images for Invoices Section
export const HomeBannerBG = require('../assets/images/home/banner-home.svg');
export const Brain = require('../assets/images/brain.png');

// Project images for Home Page
export const Play = require('../assets/images/home/play.png');
export const Mobiprint = require('../assets/images/home/mobiprint.jpg');
export const Trivia = require('../assets/images/home/triviago.jpg');
export const Invoease = require('../assets/images/home/project-03.jpg');
export const ModalClose = require('../assets/images/home/close.png');

export const TestimonialTorben = require('../assets/images/home/torben.jpg');
export const TestimonialJosiah = require('../assets/images/home/josiah.jpg');
export const TestimonialShane = require('../assets/images/home/shane.jpg');

export const WecanSoftware = require('../assets/images/home/wecan_software.svg');
export const WecanMobile = require('../assets/images/home/wecan_mobile.svg');
export const WecanUiUx = require('../assets/images/home/wecan_uiux.svg');

// export const TestimonialClient = require('../assets/images/home/testimonals-client.jpg');

// About Us Page Images
export const AboutBannerBG = require('../assets/images/about/banner-about.svg');
export const MissionBG = require('../assets/images/about/about-startup.svg');
export const AdvantageBG = require('../assets/images/about/about-advantage.svg');

export const RentlessBG = require('../assets/images/about/relentless-dedication2.svg');
export const ImprovementBG = require('../assets/images/about/continuous-improvement.svg');
export const DotheRightBG = require('../assets/images/about/do-the-right.svg');
export const PartneringBG = require('../assets/images/about/partnering.svg');

export const TroubleShootersBG = require('../assets/images/about/about-troubleshooters.svg');
export const EachOtherBG = require('../assets/images/about/about-eachother.svg');
export const HungryBG = require('../assets/images/about/about-hungry.svg');

export const Develop01 = require('../assets/images/about/develop-01.png');
export const Develop02 = require('../assets/images/about/develop-02.png');
export const Develop03 = require('../assets/images/about/develop-03.png');
export const Develop04 = require('../assets/images/about/develop-04.png');
export const Develop05 = require('../assets/images/about/develop-05.png');

// Service Page Images
export const ServiceBannerBG = require('../assets/images/service/banner-service.svg');

export const EnterpriseSoftwareDevelopment = require('../assets/images/service/enterprise-software-development.svg');
export const MobileDevelopment = require('../assets/images/service/mobile-development.svg');
export const UIUX = require('../assets/images/service/uiux.svg');
export const QA = require('../assets/images/service/qa-testing.svg');
export const IT = require('../assets/images/service/IT-Consulting.svg');
export const Digital = require('../assets/images/service/digital-marketing.svg');

export const Photoshop = require('../assets/images/service/photoshop.svg');
export const Illustrator = require('../assets/images/service/illustrator.svg');
export const AfterEffects = require('../assets/images/service/ae.svg');
export const Sketch = require('../assets/images/service/sketch.svg');
export const Invision = require('../assets/images/service/invision.svg');

export const Flutter = require('../assets/images/service/flutter.svg');
export const ReactNative = require('../assets/images/service/react.svg');
export const Kotlin = require('../assets/images/service/kotlin.svg');
export const Swift = require('../assets/images/service/swift.svg');
export const ObjectiveC = require('../assets/images/service/objective.svg');

export const RubyOnRails = require('../assets/images/service/rails.svg');
export const Laravel = require('../assets/images/service/laravel.svg');
export const Wordpress = require('../assets/images/service/wordpress.svg');
export const Shopify = require('../assets/images/service/shopify.svg');

// Work Page Images
export const WorkBannerBG = require('../assets/images/work/banner-work.svg');

export const Arrow = require('../assets/images/work/arrow.svg');
export const Aosa = require('../assets/images/work/aosa.jpg');
export const Paybychex = require('../assets/images/work/paybychex.png');
export const MobiprintW = require('../assets/images/work/mobiprint.jpg');
export const Limocious = require('../assets/images/work/limocious.png');
export const VIT = require('../assets/images/work/vit.png');
export const InvoeaseW = require('../assets/images/work/invoease.jpg');
export const BPN = require('../assets/images/work/bpn.jpg');
export const NearBuy = require('../assets/images/work/nearbuy.jpg');
export const TriviaGo = require('../assets/images/work/triviago.jpg');

// Work Details Images
export const aosaImage = require('../assets/images/work/aosaPreview.jpg');
export const limociousImage = require('../assets/images/work/limociousPreview.jpg');
export const invoeaseImage = require('../assets/images/work/invoeasePreview.jpg');
export const vitImage = require('../assets/images/work/vitPreview.jpg');
export const bpnImage = require('../assets/images/work/bpnPreview.jpg');
export const henslerImage = require('../assets/images/work/henslerPreview.jpg');
export const nearbuyImage = require('../assets/images/work/nearbuyPreview.jpg');

// export const IconAndroid = require('../assets/images/work/android.svg');
// export const IconApple = require('../assets/images/work/apple.svg');
// export const IconDevices = require('../assets/images/work/devices.svg');

// Contact Page Images
export const Contact01 = require('../assets/images/contact/contact-01.png');
export const Contact02 = require('../assets/images/contact/contact-02.png');
export const Contact03 = require('../assets/images/contact/contact-03.png');

export const ContactBannerBG = require('../assets/images/contact/banner-contact.svg');

// Blog Page Images
export const BlogBannerBG = require('../assets/images/blogs/banner-blog.svg');
export const BlogSlide01 = require('../assets/images/blogs/blog-banner.jpg');

export const StayFocused = require('../assets/images/blogs/staying-focused.svg');
export const Wireframing = require('../assets/images/blogs/what-is-a-wireframe-why-is-wireframing-so-important.svg');
// export const Wireframing = require('../assets/images/blogs/freelancer-vs-agency.svg');

export const Ecommerce = require('../assets/images/blogs/e-commerce-in-covid-19.svg');

export const Appstore = require('../assets/images/blogs/common-reasons-why-app-store-rejects-apps-how-to-avoid-them.svg');
export const AppstoreRejects = require('../assets/images/blogs/AppstoreRejects.svg');

export const Mobileapp = require('../assets/images/blogs/important-tips-to-know-before-developing-a-mobile-app.svg');
export const SmartPhoneUsers = require('../assets/images/blogs/SmartPhoneUsers.svg');
export const SmartPhoneShoppers = require('../assets/images/blogs/SmartPhoneShoppers.svg');

// Blog Share Icons
export const FbShare = require('../assets/images/blogs/share/facebook.svg');
export const LinkedinShare = require('../assets/images/blogs/share/linkedin.svg');
export const TwitterShare = require('../assets/images/blogs/share/twitter.svg');
export const EmailShare = require('../assets/images/blogs/share/email.svg');
export const LinkShare = require('../assets/images/blogs/share/link.svg');
export const ShareIcon = require('../assets/images/blogs/share/share.svg');
export const BackIcon = require('../assets/images/blogs/share/back.svg');

// Career Page Images
export const CareerBannerBG = require('../assets/images/career/banner-career.svg');

export const CSPLteam = require('../assets/images/career/cspl-team.png');
export const CultureBG = require('../assets/images/career/culture-bg.svg');
export const Growth = require('../assets/images/career/growth.svg');
export const Interface = require('../assets/images/career/interface.svg');

// Hire Us Page Images
export const HireusBannerBG = require('../assets/images/hire-us/banner-hire-us.svg');

// Case Studies Images

//// Technologies or Platform of application
export const PlatformCrash = require('../assets/images/case-studies/technologies/crash.png');
export const PlatformFabric = require('../assets/images/case-studies/technologies/fabric.png');
export const PlatformHTML = require('../assets/images/case-studies/technologies/html.png');
export const PlatformKotlin = require('../assets/images/case-studies/technologies/kotlin.png');
export const PlatformMixpanel = require('../assets/images/case-studies/technologies/mixpanel.png');
export const PlatformParse = require('../assets/images/case-studies/technologies/parse.png');
export const PlatformPhotoshop = require('../assets/images/case-studies/technologies/photoshop.png');
export const PlatformRails = require('../assets/images/case-studies/technologies/rails.png');
export const PlatformSwift = require('../assets/images/case-studies/technologies/swift.png');
export const PlatformZeplin = require('../assets/images/case-studies/technologies/zeplin.png');


//// Trivia go
export const CSTriviagoBG = require('../assets/images/case-studies/triviago/background.png');
export const Statistics = require('../assets/images/case-studies/triviago/statistics.png');

//// Mobiprint
export const CSMobiprintBG = require('../assets/images/case-studies/mobiprint/banner.png');
export const MobiPrintPreview = require('../assets/images/case-studies/mobiprint/mobiles.png');
export const Usageicon01 = require('../assets/images/case-studies/mobiprint/icon-1.png');
export const Usageicon02 = require('../assets/images/case-studies/mobiprint/icon-2.png');
export const Usageicon03 = require('../assets/images/case-studies/mobiprint/icon-3.png');
export const Usageicon04 = require('../assets/images/case-studies/mobiprint/icon-4.png');

// Vacancies page Images
export const BriefCase = require('../assets/images/career/vacancies/briefcase.svg');
export const AndroidDeveloperBG = require('../assets/images/career/vacancies/androidBG.jpg');

//Thank you Image
export const ThankYou = require('../assets/images/Thank_you.svg');

//404 Image
export const Error404 = require('../assets/images/404.svg');

// export const ToolsSwift = require('../assets/images/career/vacancies/swift.png');
// export const ToolsParse = require('../assets/images/career/vacancies/parse.png');
// export const ToolsWordpress = require('../assets/images/career/vacancies/wordpress.png');
// export const ToolsPHP = require('../assets/images/career/vacancies/php.png');