import React from 'react'

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import { NavLink } from 'react-router-dom'

import SectionTitle from '../../components/sectionTitle'

import ProjectTitle from '../../components/projectTitle'

import {MissionBG, AdvantageBG} from '../../components/assets'

function Mission(props){
  return(
    <Container fluid className="py-5" id="our_work">
      <Container>
        <Row>
          <Col className="mb-5">
            <SectionTitle title="We Are on a Mission" color="black" />
            <p>To help our client succeed with their goals. We grow when our clients grow. We always put out clients first and thrive to make their business successful, and in turn they share the success with us. That’s how we’re built.</p>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col md="6" className="order-lg-last text-center mb-3 d-flex align-items-center">
            <div className="project-image shadow-none">
              <img src={MissionBG} alt="Our Mission" className="img-fluid" style={{width: '330px'}} />
            </div>
          </Col>
          <Col md="6" className="d-flex align-items-center">
            <div>
              <ProjectTitle title="We were a startup when we started!" />
              <p>The short answer is we were there when it all began, creating some of world’s first mobile applications in early 2008. Mobile development has changed considerably since then, and we haven’t looked back.</p>
              <p>We partner with our clients to deliver innovative digital services and solutions. All of our solutions are designed around your business objectives, whether that be driving efficiency, increasing revenue or reducing overhead costs.
              </p>
            </div>
          </Col>          
        </Row>

        <Row className="mb-4">
          <Col md="6" className="px-4 py-5 text-center d-flex align-items-center">
            <div className="project-image shadow-none">
              <img src={AdvantageBG} alt="Complitech Advantage" className="img-fluid" style={{width: '400px'}} />
            </div>
          </Col>
          <Col md="6" className="px-4 py-3 d-flex align-items-center">
            <div>
              <ProjectTitle title="The Complitech Advantage" />
              <ul className="custom-bullets">
                <li>
                  <p>Our exceptionally deep technical expertise allows us to take projects often perceived as too complex or difficult by other developers.</p>
                </li>
                <li>
                  <p>
                    We believe in working in partnership with our clients to understand their specific needs and working methods.
                  </p>
                </li>                
                <li>
                  <p>
                  Working together consistently results in creating smart solutions to meet our client's evolving business demands. This collaborative style of working has resulted in the development of many award-winning applications.</p>
                </li>
              </ul>
            </div>
          </Col>

        </Row>

        <Row>
          <Col md="12" className="text-center">
            <NavLink to="/hire-us" className="mx-3 mb-3 d-inline-block">
              <Button color="black" size="lg">Got an Idea?</Button>
            </NavLink>

            <NavLink to="/work" className="mx-3 mb-3 d-inline-block">
              <Button color="transparent" className="btn btn-outline" size="lg">See our Work</Button>
            </NavLink>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Mission;