import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {bpnImage} from '../../components/assets'

const BPNMaestro = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>

	    	<Row>
	    		<Col md="8">
	    			<img src={bpnImage} alt="BPNMaestro" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="BPNMaestro" />
		    			<WorkPostText className="mt-2 mb-3" title="Powerful, intuitive yet affordable hotel quality solutions aimed at supporting your daily operation. No fluff, no unnecessary features, but still everything you need to deliver fast and efficient guest experiences." />
		    			<WorkPostText className="mt-2 mb-3" title="BPN Maestro offers powerful tools helping you to simplify your hotel operation, supporting your frontline staff in working more efficiently while providing the analytics you need. Stop wasting time on paperwork and instead focus on whats really important – your guests." />
		    			<a href="http://app.bpn-solutions.com" target="blank">
                <Button color="black" size="lg" className="mb-3">Check out BPN Maestro</Button>
              </a>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default BPNMaestro;