import React from 'react'

function SectionTitle(props){
  return(
    <h1 className={[props.color + ' section-title']}>{props.title}</h1>
  );
}

function SectionSubTitle(props){
  return(
    <h2 className={[props.color + ' section-subtitle ' + props.className]}>{props.title}</h2>
  );
}

export default SectionTitle;

export {SectionSubTitle};