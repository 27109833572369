import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import BusinessPosts from '../../pages/blogs/blogCategory';
import SectionTitle from '../../components/sectionTitle';

function Business(){
  return(
      <div>
        <Container fluid className="bg-light">
          <Container className="pt-105">
            <Row>
              <Col md="12">
                <SectionTitle title="Business" color="black" />
              </Col>
            </Row>

            <Row>
              <Col md="12" className="py-5">
                <BusinessPosts category="business" />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    );
  }
  export default Business;