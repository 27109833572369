import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import workJSON from '../../data/work.json';

import WorkCard from './workCard';

const workCategoryPosts = (props) => {
  const workPosts = props.category ? workJSON.filter(workPost => workPost.category === props.category) : workJSON;

  return (
    <Container>

      {/* <Row>
        <Col lg="12" className="mb-4">
          <SectionTitle title="Our Work" color="black" />
          <p>Over the years we’ve worked with incredible partners to craft ecommerce and help their businesses thrive. Check out some of our best work.</p>
        </Col>
      </Row> */}

      {/*<Row>
        {workPosts.map((post,index) => (
          <>
          <Col md="6" className="mb-4 px-3 px-md-4 px-lg-5">
            <WorkCard 
              title={post.title}
              url={"/" + post.category + "/" + post.slug}
              image={post.image}
              description={post.description}
            />
          </Col>
          {/* {index % 2 === 0 && <Col md="1" className="mb-4"></Col>}
          </>
          ))}
      </Row>*/}

      <Row>
        {workPosts.map((post,index) => (
          <Col md="6" className="mb-4 px-3 px-md-4 px-lg-5">
            <WorkCard
              title={post.title}
              url={"/work/" + post.slug}
              image={post.image}
              description={post.description}
            />
          </Col>
          ))}
      </Row>

    </Container>
  );
};

export default workCategoryPosts;