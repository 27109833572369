import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom'

import {CopyToClipboard} from 'react-copy-to-clipboard';


import{
  Row,
  Col,
  UncontrolledCollapse,
  Button,
} from 'reactstrap';

import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
} from "react-share";

import {
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  EmailIcon,
} from "react-share";

import {
  // FbShare,
  // LinkedinShare,
  // TwitterShare,
  // EmailShare,
  // LinkShare,
  ShareIcon,
  BackIcon
} from '../../components/assets';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faLink, faChevronLeft, faShare, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { useLocation } from 'react-router-dom'

library.add(fab, faLink, faChevronLeft, faShare, faEnvelope)



// SIDE BAR
function Sidebar(props){
const [copyLink, setCopyLink] = useState(null)
  useEffect(()=>{
    let copyLink = window.location.href
    setCopyLink(copyLink)
  }, [])
  return(
    <div className="sticky-top">
      <NavLink to="/blog" className="back-arrow-link"><FontAwesomeIcon icon="chevron-left" /> Back to Ideas</NavLink>
      
      <Row className="py-4">
        <Col>
          <p className="mb-0"><strong>POSTED IN</strong></p>
          <NavLink to={props.categoryLink} className="highlight yellow">{props.categoryName}</NavLink>
        </Col>
      </Row>

      <Row>
        <Col md="12" className="px-4">
          <hr className="my-0"></hr>
        </Col>
      </Row>

      <Row className="py-4 share-on-social-media">
        <Col lg="12" className="mb-3 col">
          <FacebookShareButton url={copyLink} quote={props.title}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </Col>
          
        <Col lg="12" className="mb-3 col">
          <TwitterShareButton url={copyLink} title={props.title}>
            <TwitterIcon size={32} round={true} />
          </TwitterShareButton>
        </Col>
        
        <Col lg="12" className="mb-3 col">
          <LinkedinShareButton url={copyLink} title={props.title}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
        </Col>
        
        <Col lg="12" className="mb-3 col">
          <EmailShareButton url={copyLink} subject={props.title}>
            <EmailIcon size={32} round={true} />
          </EmailShareButton>
        </Col>
        
        <Col lg="12" className="mb-3 col">
          <CopyToClipboard text={copyLink} className="copy-link-icon">
            <FontAwesomeIcon icon="link" />
          </CopyToClipboard>
        </Col>
      </Row>
    </div>
  );
}

// Bottom Bar
function Bottombar(props){
  
  const [copyLink, setCopyLink] = useState(null)
  useEffect(()=>{
    let copyLink = window.location.href
    setCopyLink(copyLink)
  }, [])
  return(<>
    <Col md="12" className="blog-bottom-bar">
      
      <UncontrolledCollapse toggler="#toggler">
        <div className="sharing-button-bar">
          <div className="bg-layer" id="toggler"></div>
          <Row className="share-on-social-media py-4 px-4 py-md-5">
            <Col className="px-0 text-center">
              <FacebookShareButton url={copyLink} quote={props.title} className="copy-link-icon">
                <FacebookIcon size={32} round={true} />
                {/* <img src={FbShare} alt="share-icon" /> */}
                {/* <FontAwesomeIcon icon={['fab', 'facebook-f']} /> */}
              </FacebookShareButton>
              <p>Facebook</p>
            </Col>
              
            <Col className="px-0 text-center">
              <TwitterShareButton url={copyLink} title={props.title} className="copy-link-icon">
                <TwitterIcon size={32} round={true} />
                {/* <img src={TwitterShare} alt="share-icon" /> */}
                {/* <FontAwesomeIcon icon={['fab', 'twitter']} /> */}
              </TwitterShareButton>
              <p>Twitter</p>
            </Col>
            
            <Col className="px-0 text-center">
              <LinkedinShareButton url={copyLink} title={props.title} className="copy-link-icon">
                <LinkedinIcon size={32} round={true} />
                {/* <img src={LinkedinShare} alt="share-icon" /> */}
                {/* <FontAwesomeIcon icon={['fab', 'linkedin-in']} /> */}
              </LinkedinShareButton>
              <p>Linkedin</p>
            </Col>
            
            <Col className="px-0 text-center">
              <EmailShareButton url={copyLink} subject={props.title} className="copy-link-icon">
                <EmailIcon size={32} round={true} />
                {/* <img src={EmailShare} alt="share-icon" /> */}
                {/* <FontAwesomeIcon icon="envelope" /> */}
              </EmailShareButton>
              <p>Email</p>
            </Col>
            
            <Col className="px-0 text-center">
              <CopyToClipboard text={copyLink} className="copy-link-icon">
                <FontAwesomeIcon icon="link" />
                {/* <img src={LinkShare} alt="share-icon" /> */}
              </CopyToClipboard>
              <p>Copy Link</p>
            </Col>
          </Row>
        </div>
      </UncontrolledCollapse>
    
      <Row className="d-flex align-items-center justify-content-between">
        <Col className="d-flex align-items-center">
          <NavLink to="/blog" className="back-arrow-link m-0"><img src={BackIcon} alt="Back-icon" /> Back</NavLink>
        </Col>

        <Col className="d-flex align-items-center justify-content-center">
          <NavLink to={props.categoryLink}>{props.categoryName}</NavLink>
        </Col>
      
        <Col className="d-flex align-items-center justify-content-end">
          <Button id="toggler" className="share-btn">
            {/* <FontAwesomeIcon icon="share" /> */}
            Share <img src={ShareIcon} alt="share-icon" />
          </Button>
        </Col>
      </Row>
    </Col>
  </>);
}

export default Sidebar;
export {Bottombar};