import React, {Component} from 'react'
import{
  Container,
  Row,
  Col
} from 'reactstrap';

import {Brain} from './assets'

import HireUsModal from './hire_us_modal'

export default class GetinTouch extends Component{
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      displayModal : false
    };    
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  triggerredModal = () => {
    const {displayModal} = this.state;
    this.setState({
      displayModal: !displayModal
    })
  }

  render(){
  return(
    <Container fluid className="bg-white text-center py-2 get-in-touch">
      <HireUsModal onRef={(ref) => (this.parentRef = ref)}	parentRef={this.triggerredModal.bind(this)} displayModal={this.state.displayModal} />
      <Row className="my-5">
        <Col>
          <img src={Brain} alt="Mind" className="mb-3" />
          <h3 className="mb-0">Got a Project or Idea in Mind? Not sure where to start?</h3>
          <h3 className="mt-0">Speak to one of our experts. Take the first step towards success.</h3>
          <a onClick={this.triggerredModal}>Get in Touch</a>
        </Col>
      </Row>
    </Container>
  );
 }
}