import React from 'react'

function ToolsTitle(props){
  return(
    <h2 className={"blogpost-title " + props.className || ""}>{props.title}</h2>
  );
}

function ToolsText(props){
  return(
    <p className="blogpost-text mb-0">{props.title}</p>
  );
}

export default ToolsTitle;
export {ToolsText}