import React from 'react'

import { NavLink } from 'react-router-dom';

function BlogSubject(props){
  return(
    <h1 className="blog-subject"><NavLink to={props.link || '/'}>{props.title}</NavLink></h1>
  );
}

function BlogData(props){
  return(
    <h6 className="blog-data">{props.title}</h6>
  );
}

function Category(props){
  return(
    <h6 className="blog-data"><NavLink to={props.link || '/'}>{props.title}</NavLink></h6>
  );
}

export default BlogSubject;
export {BlogData, Category};