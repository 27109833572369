import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import SectionTitle, {SectionSubTitle} from '../../components/sectionTitle'
import ProjectTitle from '../../components/projectTitle'

import {BlogQuote} from '../blogs/blogPostTags';

import {RentlessBG, ImprovementBG, DotheRightBG, PartneringBG} from '../../components/assets'

function Value(props){
  return(
    <Container fluid className="py-5" id="our_work">
      <Container>
        <Row>
          <Col lg="6" className="mb-5">
            <div className="sticky-top-200">
              <SectionTitle title="Our Values" color="black" />
              <SectionSubTitle title="We believe in what Albert Einstein said," color="black" />
              <Row>
                <Col>
                  <BlogQuote size="md" title={"Strive not to be a SUCCESS, but rather to be of VALUE"} />
                </Col>
              </Row>
            </div>
          </Col>

          <Col lg="1"></Col>
          
          <Col lg="5">
            <div className="mtb-80 mt-0">
              <div className="media">
                <img src={RentlessBG} alt="Do the Right Thing" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <ProjectTitle title="Rentless Dedication" />
                  <p className="mt-0">We are driven to meet client needs with persistence and grit. We love to be challenged by tough problems and do not rest until it is solved, the right way.</p>
                </div>
              </div>
            </div>

            <div className="mtb-80">
              <div className="media">
                <img src={ImprovementBG} alt="Continuous Improvement" className="img-fluid mb-3 mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <ProjectTitle title="Continuous Improvement" />
                  <p>We believe in culture of innovation and continuous improvement, where we can push the boundaries and learn from things that don’t always go according to plan. Basically, you’re safe to try new things.</p>       
                </div>
              </div>
            </div>

            <div className="mtb-80">
              <div className="media">
                <img src={DotheRightBG} alt="Do the Right Thing" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <ProjectTitle title="Do the right thing" />
                  <p>The best thing one can do is the right thing. We stand for what is just right, and hold ourselves to a high level of morals by listening, understanding different perspectives, and caring about others’ success.</p>                  
                </div>
              </div>
            </div>

            <div className="mtb-80">
              <div className="media">
                <img src={PartneringBG} alt="Partnering With Clients" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <ProjectTitle title="Partnering with clients" />
                  <p>By listening, collaborating, and seeking to grow together, we don’t just do what our clients say us to, we do what is best for our clients.</p>                  
                </div>
              </div>
            </div>

          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Value;