import React from 'react';

import {
  Container
} from 'reactstrap';

import ToolsPosts from './tools';

const ToolsPost = (props) => {
  return (
    <Container fluid className="py-5">
      <ToolsPosts />
    </Container>
  );
};

export default ToolsPost;