import React from 'react';

import { NavLink } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {Arrow} from '../../components/assets'

export default (props) => {
  return(    
    <NavLink to={props.url || "#"} className="works">

      {/*<Row className="mt-4">
        <Col md="12">
          <CardImg
            src={ require('../../assets/images/work/'+props.image) }
            width="100%"
            className="workImg"
          />
        </Col>
      </Row>

      <Row className="mt-4 p-3">
        <Col className="col-9">
          <WorkPostTitle title={props.title} />   
        </Col>
        <Col className="col-3 text-right">
          <NavLink to={props.url || "#"}>
            <img src={Arrow} alt="arrow" className="cus-arrow" />
          </NavLink>
        </Col>
        <Col md="12">
          <WorkPostText title={props.description} />
        </Col>
      </Row>*/}

      <Card className="mt-5">
        <CardImg
          src={ require('../../assets/images/work/'+props.image) }
          width="100%"
          className="workImg"
        />
        
        <CardBody className="mt-2">
          <Row>
            <Col className="col-9">
              <WorkPostTitle title={props.title} />   
            </Col>
            <Col className="col-3 text-right">
              <img src={Arrow} alt="arrow" className="cus-arrow" />
            </Col>
            <Col md="12">
              <WorkPostText title={props.description} />
            </Col>
          </Row>
        </CardBody>
      </Card>

    </NavLink>
  );
}