import React from 'react'
import { NavLink } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'

library.add(faLongArrowAltRight)

function ProjectTitle(props){
  return(
    <h2 className={['project-title ' + props.className ]}>{props.title}</h2>
  );
}

function LargeText(props){
  return(
    <p className={[props.color + ' large-text ' + props.className ]}>{props.title}</p>
  );
}

function ProjectLink(props){
  return(
    <NavLink to={props.link} className="project-link">View <FontAwesomeIcon icon="long-arrow-alt-right" /></NavLink>
  );
}

export default ProjectTitle;
export {LargeText, ProjectLink};