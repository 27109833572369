import React from 'react';

import { Helmet } from 'react-helmet-async';

import PageBanner from '../../components/pageBanner';
import WorkSection from './workSection';
import Expertise from './expertise';
import BetterProcess from './process';
import Blog from '../../components/blog';
import Testimonials from '../../components/testimonial';

import GetinTouch from '../../components/getinTouch';

import {TestimonialTorben, HomeBannerBG} from '../../components/assets';

function Home(props){
  return(
      <div>
        <Helmet>
          <title>Complitech - web / mobile development partner</title>
          <meta property="og:title" content="Complitech - web / mobile development partner" />
          <meta property="og:image" content={HomeBannerBG} />
          <meta property="og:type" content="page" />
          <meta property="og:description" content="We are an agency focused on getting real business results for companies from their digital presence." />
          <meta name="description" content="We are an agency focused on getting real business results for companies from their digital presence." />
        </Helmet>
        <PageBanner 
          bannerImage={HomeBannerBG} 
          bannerImageAlt="Complitech - web / mobile development partner" 
          title={<>We could be your next <span className="highlight green" key="1">Technology Partner</span></>}
          description="We are an agency focused on getting real business results for companies from their digital presence."
        />
        <Expertise />
        <WorkSection />
        <Testimonials
          testimonialImage={TestimonialTorben}
          testimonialImageAlt="testimonial-torben"
          title="In my new project James Ay, Complitech has made a Shopify Solution and all the integrations, customizations, etc. I can give my warmest recommendation. Complitech is a great company and they're easy to work with."
          testimonialVideo="https://www.youtube-nocookie.com/embed/GqRDfy6S5UE?autoplay=1;rel=0"
        />
        <BetterProcess />
        <Blog />
        <GetinTouch />
      </div>
    );
  }
  export default Home;