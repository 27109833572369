import React from 'react'

import { Helmet } from 'react-helmet-async';

// Page Progressbar
import PageProgress from 'react-page-progress'

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {Appstore, AppstoreRejects} from '../../components/assets';

import SectionTitle from '../../components/sectionTitle';

import {BlogPostDate, BlogHashTags, PrevBlog, NextBlog} from '../../pages/blogs/blogPostTags';

import { LargeText } from '../../components/projectTitle';

import Sidebar, {Bottombar} from '../../pages/blogs/sidebar';

import{
  EcommerceInCOVID19,
  WhatIsWireframeWhyIsWireframingSoImportant,
} from '../../pages/blogs/blogList';

function BlogAppstore() {
  return(<>
    <PageProgress color="var(--blue)" height={3} className="page-progress-bar"/>
    
    <Container fluid className="px-0">
      <Helmet>
        <title>App Store</title>
        <meta property="og:type" content="article" />
        <meta name="description" content="Common reasons why App Store rejects Apps & How to avoid them" />
      </Helmet>

      <img src={Appstore} alt="Appstore" className="img-fluid w-100" />

      <Container className="py-5 our-voice">
        <Row>
          {/* SIDEBAR */}
          <Col lg="3" className="blog-left-sidebar">
            <Sidebar
              categoryLink="/technical"
              categoryName="Technical"
              title="Common reasons why App Store rejects Apps & How to avoid them"
            />
          </Col>

          {/* BLOG CONTENT */}
          <Col lg="9">
            <Row>
              <Col>
                <SectionTitle title="Common reasons why App Store rejects Apps & How to avoid them" color="black" />
                <BlogPostDate title="4 min read" />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <p>With almost 1.84 million apps available in the iOS app store as of 4th quarter 2019, entering such a crowded market is not a cakewalk.</p>

                <p>Let’s assume you’ve worked persistently to create your app, and then you are being notified that Apple has rejected your app. Now what? Submitting a new app or updates to the app stores is always an exciting time. Unlike websites, mobile apps go through a review and approval process before they are released. One of the worst-case scenarios after submission is an app rejection.</p>

                <LargeText title="According to Apple, 88% of those rejections occur because of the most common reasons." />

                <img src={AppstoreRejects} alt="Appstore Rejects" className="img-fluid d-block mx-auto" />

                <p>Recently, Apple proclaimed the main reasons for app rejection. And we know that Apple is known for its high standards and difficult policies for app developers. Want to avoid being rejected by Apple? Check out some of these common mistakes developers should avoid before app submission.</p>

                <h3>Incomplete information</h3>
                <p>Always enter all the details needed to review your app in the App Review Information section of iTunes Connect. Don’t just place placeholder images in screenshot fields or add dummy text to a description. If some features require signing in, then provide a valid demo account username and password. Also, make sure that you provide correct contact information. One of the major requirements of apps in the App Store is that they have metadata. That simply means you need to include screenshots, descriptions, etc. This mistake is very easy to fix but it can add days to the review period so it’s easy to avoid.</p>

                <h3>Crashes and Bugs</h3>
                <p>Apple wants users to be happy with all the apple products. So they have humans reviewing the apps. If your app has any bug or crashes very often, it will definitely be rejected. Make sure you fix all the bugs before submitting the app.</p>

                <h3>Repeated submission of similar apps</h3>
                <p>Always come up with new ideas and your own ideas. Don’t just copy the latest popular apps and submit to the App Store. If you just make a few changes to the existing app and change the name of the app, and submit as your own app, Apple will definitely reject your app. Each of your apps should have something different in order to have the chances of being accepted.</p>

                <h3>Substandard User Interface</h3>
                <p>Apple places a high value on clean and user-friendly interfaces. Make sure your design meets these requirements. Always follow their design guides. And remember that even after your app has been approved, you should update your app to ensure it remains functional and engaging to new and existing customers. Apps that stop working or offer a degraded experience to users may be removed from the App Store at any time.</p>

                <p>UI Design Dos and Don’ts</p>
                <ul className="custom-bullets">
                  <li><p>Create a layout that fits the screen of an iOS device. Users should see primary content without zooming or scrolling horizontally.</p></li>
                  <li><p>Use UI elements that are designed for touch gestures to make interaction with your app feel easy and natural.</p></li>
                  <li><p>Create controls that measure at least 44 points x 44 points so they can be accurately tapped with a finger.</p></li>
                  <li><p>Text should be at least 11 points so it's legible at a typical viewing distance without zooming.</p></li>
                  <li><p>Make sure there is ample contrast between the font color and the background so the textis legible.</p></li>
                  <li><p>Don't let text overlap. Improve legibility by increasing line height or letter spacing.</p></li>
                  <li><p>Provide high-resolution versions of all image assets. Images that are not @2x and @3x will appear blurry on the Retina display.</p></li>
                  <li><p>Always display images at their intended aspect ratio to avoid distortion.</p></li>
                  <li><p>Create an easy-to-read layout that puts controls close to the content they modify.</p></li>
                  <li><p>Align the text, images, and buttons to show users how information is related.</p></li>
                </ul>

                <h3>Broken Links</h3>
                <p>In your app, all links must be functional. Your app will automatically be rejected if it contains broken links. For example, you should provide a link to user support with up-to-date contact information in all the apps. If you’re offering auto-renewable or free subscriptions on your app, you should provide a link to privacy policy.</p>

                <h3>Unrelated keywords</h3>
                <p>Keywords help users to find the app. Thus, if you’ve used keywords that are incorrect or are unrelated to the actual mobile application can be a cause for quick rejection from Apple. It’s not legal to use titles that are misleading.</p>

                <h3>Payment System</h3>
                <p>If your app takes payments to unlock functionality or allow the user to download digital content, transactions must go through the official Apple in-app purchasing system. This is to ensure that money is securely transferred via Apple’s marketplace.</p>

                <h3>Advertisements</h3>
                <p>When you’ll submit your app, you’ll be asked whether your app uses an Advertising Identifier to serve advertisements. If you say yes but if your app doesn’t have ad functionality, it can be rejected. Make sure you test your app on an iOS device. Similarly, if you say no but if it contains Ads, the app can be rejected.</p>

                <h3>Using Apple’s Name</h3>
                <p>It includes trademarks, app names and content in your app. Doing so is likely to conflict with Apple’s content regulations and have your app rejected if you’re putting them into your app without permission. For example, many developers use iPhone-like icons, Polaroid-like image frames in their app without any permission.</p>

                <h3>User’s privacy</h3>
                <p>Privacy is becoming one of the most important factors. Most of the apps of your phone seek permission to use your personal information. For example, if you install an online dating app, it will ask your name, age, gender, height, etc. Will you appreciate it if your app automatically picks up these from your phone without any permission? I’ll definitely not like this app. And Apple also shares mutual feelings and it will definitely reject your app.</p>

                <p>It is not the end of the world if your app is being rejected, because you can always try again. But to avoid this in the first place you should keep all these points in your mind. There can be many other reasons also above the ones I mentioned here. All you should take care of the quality of your iOS app.</p>

                <p>Happy Submissions :)</p>

                <BlogHashTags title="#appstore" />
                <BlogHashTags title="#apps" />
                <BlogHashTags title="#avoid" />
                <BlogHashTags title="#appstorerejects" />
                <BlogHashTags title="#rejectapps" />
              </Col>
            </Row>

          </Col>
        
          {/* BOTTOM BAR */}
          <Bottombar 
            categoryLink="/technical"
            categoryName="Technical"
            title="Common reasons why App Store rejects Apps & How to avoid them"
          />

        </Row>
      </Container>

      <Container>
        <Row className="next-prev-blog py-5">
          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'0'}} className="text-center text-lg-left mb-4 mb-lg-0">
            <PrevBlog
              to="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important"
              title="What is a Wireframe? Why is wireframing so important?"
            />
          </Col>

          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'4'}} className="text-center text-lg-right">
            <NextBlog
              to="/business/e-commerce-in-covid-19"
              title="E-commerce in COVID 19"
            />
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-5 bg-light related-posts">
      <Container>
        <SectionTitle title="Related Posts" />

        <Row className="blog-post mt-5">
          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <EcommerceInCOVID19 />
          </Col>

          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <WhatIsWireframeWhyIsWireframingSoImportant />
          </Col>

        </Row>
      </Container>
    </Container>

  </>);

}

export default BlogAppstore;