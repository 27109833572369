import React from 'react'
import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';
import {NavLink} from 'react-router-dom'

import SectionTitle from '../../components/sectionTitle'
import ProjectTitle, {ProjectLink} from '../../components/projectTitle'

import {Mobiprint, Trivia, Invoease} from '../../components/assets'

function WorkSection(props){
  return(
    <Container fluid className="py-5" id="our_work">
      <Container>
        
        {/*<Row>
          <Col className="mb-5">
            <SectionTitle title="What To Expect Working With Us" color="black" />
          </Col>
        </Row>
        
        <Row className="mb-4 mx-0">
          <Col md="6" className="text-center d-flex align-items-center">
            <div className="project-image">
              <img src={Mobiprint} alt="Mobiprint" className="img-fluid" />
            </div>
          </Col>
          <Col md="6" className="px-md-4 py-3 bg-light d-flex align-items-center">
            <div>
              <ProjectTitle
                  title="Mobiprint"
              />

              <p>
              Mobiprint is an iPhone and android app, allowing users to take printouts from an iPhone, iPad and An-droid and make the whole process of taking printouts flexible and dynamic. Client's mission was to pro-vide the highest quality print utility at an affordable price with a wide variety of solutions supported. Thus, clients wanted to make an application that allows everyone to take printouts from an iPhone / iPad.
              </p>

              <ProjectLink
                link="/case-study/mobi-print"
              />
            </div>
          </Col>          
        </Row>

        <Row className="mb-4 mx-0">
          <Col md="6" className="bg-dark-blue px-4 py-5 text-center order-md-last d-flex align-items-center">
            <div className="project-image">
              <img src={Triviago} alt="TriviaGo" className="img-fluid" />
            </div>
          </Col>
          <Col md="6" className="px-md-4 py-3 bg-light d-flex align-items-center">
            <div>
              <ProjectTitle
                  title="TriviaGo"
              />
              
              <p>
              The Client, a US company, that produces and advertises digital solutions for local businesses, asked us to collaborate on a trivia game concept. They wanted a scalable solution that can start from a small number of participating bars/restaurants, and can be pitched to businesses located in other states of the USA. The client wanted to confine the app release to Christmas for best sales results and we had exactly 4.5 months to build the solution.
              </p>

              <ProjectLink
                link="/case-study/triviago"
              />
            </div>
          </Col>
        </Row>

        <Row className="mb-4 mx-0">
          <Col md="6" className="bg-dark-green px-4 py-5 text-center d-flex align-items-center">
            <div className="project-image">
              <img src={Invoease} alt="Invoease" className="img-fluid" />
            </div>
          </Col>
          <Col md="6" className="px-md-4 py-3 bg-light d-flex align-items-center">
            <div>
              <ProjectTitle
                  title="Invoease"
              />

              <p>
                At Invoease we try to simplify invoicing and take greater control of your cash flow with online invoicing built for businesses of any size. Automation helps you invoice on time. Discount terms help you get paid early. And Mobile apps help you simply squeeze more time out of your day.
              </p>

              <ProjectLink
                link="/"
              />
            </div>
          </Col>
        </Row>*/}

        <Row>
          <Col lg="5" className="mb-5">
            <div className="sticky-top-300">
              <SectionTitle title="What To Expect" color="black" />
              <SectionTitle title="Working With Us" color="black" />
              <Col className="px-0 mt-4 d-none d-lg-block">
                <NavLink to="/work"><Button color="black" size="lg">More Work</Button></NavLink>
              </Col>
            </div>
          </Col>
          
          <Col lg="7" className="pl-90">
            <div className="mtb-80 mt-0">
              <img src={Mobiprint} alt="Mobiprint" className="img-fluid mb-4 shadow" />
              <ProjectTitle title="Mobiprint" className="my-3" />
              <p className="mt-0">Mobiprint is an iPhone and android app, allowing users to take printouts from an iPhone, iPad and An-droid and make the whole process of taking printouts flexible and dynamic.</p>
              <ProjectLink link="/work/mobi-print" />
            </div>

            <div className="mtb-80">
              <img src={Trivia} alt="TriviaGo" className="img-fluid mb-4 shadow" />
              <ProjectTitle title="Triviago" className="my-3" />
              <p>The Client, a US company, that produces and advertises digital solutions for local businesses, asked us to collaborate on a trivia game concept.</p>
              <ProjectLink link="/work/triviago" />
            </div>

            <div className="mtb-80">
              <img src={Invoease} alt="Invoease" className="img-fluid mb-4 shadow" />
              <ProjectTitle title="Invoease" className="my-3" />
              <p>At Invoease we try to simplify invoicing and take greater control of your cash flow with online invoicing built for businesses of any size. Automation helps you invoice on time.</p>
              <ProjectLink link="/" />
            </div>
          </Col>
        </Row>

        <Col className="px-0 mt-4 d-lg-none">
          <NavLink to="/work"><Button color="black" size="lg">More Work</Button></NavLink>
        </Col>

      </Container>
    </Container>
  );
}

export default WorkSection;