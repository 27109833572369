import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Alert
} from 'reactstrap';

import SectionTitle, {SectionSubTitle} from '../../components/sectionTitle';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

export default class ContactForm extends Component {

  constructor(props){
    super(props);
    this.state = {
      contactinquiry: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
      },
      message: "",
      color: "",
      name_error: "",
      email_error: "",
      phone_error: "",
      subject_error: "",
      message_error: "", 
      form_progress: false   
      }
  }

  updateInquiry = (event) => {
    const {name, value} = event.target;
    this.setState({
      contactinquiry: {
      ...this.state.contactinquiry,
      [name]: value
      }
    }, function(){
      this.customCheckFormValidation(name);
    })
  }

  customCheckFormValidation = (name) => {
    let name_error = "";
    let phone_error = "";
    let email_error = "";
    let subject_error = "";
    let message_error = "";

    if (name === "name"){
      if (this.state.contactinquiry.name === ""){
        name_error = "Name can't be blank!"
      }
      this.setState({
        name_error
      });
    }else if (name === "email") {
      if (this.state.contactinquiry.email === ""){
        email_error = "Email can't be blank!"
      }else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.contactinquiry.email)))
      {
        email_error = "Invalid email!"
      }
      this.setState({
        email_error
      });
    }else if (name === "phone") {
      if (this.state.contactinquiry.phone === ""){
        phone_error = "Phone number can't be blank!"
      }else if (isNaN(this.state.contactinquiry.phone)) {
        phone_error = "Phone should be Numeric"
      }else if (this.state.contactinquiry.phone.length < 10){
        phone_error = "Phone number length is small."
      }
      this.setState({
        phone_error
      });
    }else if (name === "subject"){
      if (this.state.contactinquiry.subject === ""){
        subject_error = "Subject can't be blank!"
      }
      this.setState({
        subject_error
      });
    }else if (name === "message"){
      if (this.state.contactinquiry.message === ""){
        message_error = "Message can't be blank!"
      }
      this.setState({
        message_error
      });
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    let formValid = this.checkFormValidation();
    console.log(formValid);
    
    if (formValid){
      this.setProgress();
      this.submitForm();
    }
  }

  setProgress = () => {
    this.setState({
      message: "In Progress..Please Wait!!",
      color: "primary",
      form_progress: true
    })
  }


  submitForm = () => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + "/contact_inquiry"
  	fetch(url ,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/vnd.cspl_backend.v1",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "*",
            "Access-Control-Expose-Headers": "*",
            "Access-Control-Max-Age": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify({contactinquiry: this.state.contactinquiry}),
        }).then(res => res.json())
        .then((result) => { 
        if (result.status === 200) {
          window.location.href = '/thankyou_for_inquiry' 
        }else if(result.status === 401){
          this.setState({message: result.message,
          color: "danger",
        });
        this.resetState();   
        }
        else {
          this.setState({message: result.message,
          color: "danger"});
          this.resetState();
        }
        this.clearMessageTimeout = setTimeout(() => {
            this.setState(() => ({message: "", modal: false}))
        }, 3000);
            }, (error) => {
            });
    }

  resetState(){
    this.setState({
      contactinquiry: {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: ""
      },
      form_progress: false
    })
  }

  checkFormValidation  = () => {
    let email_error = "";
    let name_error = "";
    let phone_error = "";
    let subject_error = "";
    let message_error = "";
  
    if (this.state.contactinquiry.email === ""){
      email_error = "Email can't be blank!"
    }else if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.contactinquiry.email)))
    {
      email_error = "Invalid email!"
    }
    if (this.state.contactinquiry.name === ""){
      name_error = "Name can't be blank!"
    }
    if (this.state.contactinquiry.phone === ""){
      phone_error = "Phone number can't be blank!"
    }else if (isNaN(this.state.contactinquiry.phone)) {
      phone_error = "Phone should be Numeric"
    }else if (this.state.contactinquiry.phone.length < 10){
      phone_error = "Phone number length is small."
    }else if (this.state.contactinquiry.phone.length > 10) {
      phone_error = "Phone number length is too large."
    }
    if (this.state.contactinquiry.subject === ""){
      subject_error = "Subject can't be blank!"
    }
    if (this.state.contactinquiry.message === ""){
      message_error = "Message can't be blank!"
    }
    
    this.setState({
      email_error,
      name_error,
      phone_error,
      subject_error,
      message_error
    })

    if(name_error !== "" || email_error !== "" || phone_error !== "" || subject_error !== "" || message_error !== ""){  
      return false
    }else{
      return true
    }
  }

  checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
      ? event.which
      : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }

  addErrorMessage = (msg) => {
		if (msg === ""){
			return ;
		}else{
			return (<span className="error-class"> {msg} </span>);
		}
  }

  render(){
  return (
    <Container fluid className="py-5" id="get-in-touch">
      <Container>
      <div>
        {
          this.state.message ? <Alert color={this.state.color}>{this.state.message}</Alert> : null
        }
      </div>
        <Row>
          <Col lg="8">
            <Form>
              <Row>
                <Col md="12" className="mb-4">
                  <SectionTitle title="Got a Question?" color="black" />
                  <SectionSubTitle title="Drop us a message" color="black" />
                </Col>

                <Col md="6">
                  <FormGroup className="form-field">
                    <Input type="text" name="name" value= {this.state.contactinquiry.name} autoComplete="off" onChange= {this.updateInquiry} id="yourName" placeholder="Your Name" />
                    {this.addErrorMessage(this.state.name_error)}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="form-field">
                    <Input type="email" name="email" value= {this.state.contactinquiry.email} autoComplete="off" onChange= {this.updateInquiry} id="yourEmail" placeholder="Your Email" />
                    {this.addErrorMessage(this.state.email_error)}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="form-field">
                    <Input type="text" name="phone" value= {this.state.contactinquiry.phone} onKeyPress= {this.checkNumeric} maxLength= "10" autoComplete="off" onChange= {this.updateInquiry} id="phone" placeholder="Phone" />
                    {this.addErrorMessage(this.state.phone_error)}
                  </FormGroup>
                </Col>

                <Col md="6">
                  <FormGroup className="form-field">
                    <Input type="text" name="subject" value= {this.state.contactinquiry.subject} autoComplete="off" onChange= {this.updateInquiry} id="subject" placeholder="Subject" />
                    {this.addErrorMessage(this.state.subject_error)}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <FormGroup className="form-field">
                    <Input type="textarea" name="message" value= {this.state.contactinquiry.message} autoComplete="off" onChange= {this.updateInquiry} id="message" placeholder="Message" rows="4"/>
                    {this.addErrorMessage(this.state.message_error)}
                  </FormGroup>
                </Col>

                <Col md="12">
                  <Button color="black" size="lg" className="mt-4" onClick={this.submitHandler}>Send</Button>
                </Col>
              </Row>

            </Form>
          </Col>

          <Col lg="4" className="bg-black d-flex align-items-center contact-social mt-5 mt-lg-0">
            <ul className="list-unstyled">
              <li>
                <a href="https://www.facebook.com/complitech" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                  Follow us on Facebook
                </a>
              </li>

              <li>
                <a href="https://www.linkedin.com/company/complitech-solutions/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                  Connect with us on Linkedin
                </a>
              </li>

              <li>
                <a href="https://www.instagram.com/complitech/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'instagram']} />
                  Follow us on Instagram
                </a>
              </li>

              <li>
                <a href="https://www.youtube.com/complitech" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'youtube']} />
                  Subscribe us on Youtube
                </a>
              </li>

              <li>
                <a href="skype:complitech.us?chat" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'skype']} />
                  Let's Talk
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
}
