import React from 'react'

import{
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import {NavLink} from 'react-router-dom';

import ExpertiseTitle, {ExpertiseText} from '../../components/expertiseComponents';
import SectionTitle from '../../components/sectionTitle';

import {WecanSoftware, WecanMobile, WecanUiUx} from '../../components/assets';

function Expertise(){
  return(
    <Container fluid className="py-5 bg-light">
      <Container>
        <Row>
          <Col className="mb-3 mb-md-5">
            <SectionTitle title="What we can do for you?" />
            <p>Whether you’re a start-up or a Fortune 500 company looking for a refresh, our full-service capabilities will allow you to reimagine the way you do digital. You demand - We deliver.</p>
          </Col>
        </Row>
          
        <Row className="mx-0">
          <Col lg="4" className="expertise p-4 p-md-5">
            <img src={WecanSoftware} alt="Enterprise Software" className="img-fluid mb-3" />
            <ExpertiseTitle title="Enterprise Software" />            
            <ExpertiseText title="We extend end-to-end software development services by using the latest web technologies." />
          </Col>

          <Col lg="4" className="expertise p-4 p-md-5">
            <img src={WecanMobile} alt="Mobile Apps" className="img-fluid mb-3" />
            <ExpertiseTitle title="Mobile Apps" />
            <ExpertiseText title="We've made a mark developing several hundred iPhone & android apps." />
          </Col>

          <Col lg="4" className="expertise p-4 p-md-5">
            <img src={WecanUiUx} alt="UI/UX Design" className="img-fluid mb-3" />
            <ExpertiseTitle title="UI/UX Design" />
            <ExpertiseText title="We help startups and businesses by building interfaces that are effort-less, innate and deeply engaging." />
          </Col>
        </Row>

        <Row className="mx-0">
          <Col className="text-center px-0 mt-4">
            <NavLink to="/service">
              <Button color="black" size="lg">View All</Button>
            </NavLink>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default Expertise;