import React from 'react'

import{
  Container,
  Row,
  Col
} from 'reactstrap'

import Modal from 'react-modal';

import SectionTitle from './sectionTitle'
// import {BlogQuote} from '../pages/blogs/blogPostTags';

import {Play, ModalClose} from '../components/assets';

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById('main'));

function Testimonial(props){
  var subtitle;
  const [modalIsOpen,setIsOpen] = React.useState(false);
  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    subtitle.style.color = '#f00';
  }

  function closeModal(){
    setIsOpen(false);
  }

  return(
    <Container fluid className="bg-light">
      <Container className="py-5 testimonials">
        <Row className="mb-2">
          <Col className="mb-3 mb-md-5">
            <SectionTitle title="What Our Clients Say" />
          </Col>
        </Row>

         <Row className="mx-0">
            <Col lg="5" className="p-0 p-md-0">
              <div className="px-0 position-relative pointer btn p-0" onClick={openModal}>
                <img src={Play} alt="video-play" className="videoPlay" />
                <img src={props.testimonialImage} alt={props.testimonialImageAlt} className="w-100"/>
              </div>
            </Col>

            <Col lg="7" className="p-0 p-md-0">
              <p className="blogQuoteBlk mb-0">{props.title}</p>
            </Col>        
          </Row>

        <div id="main">
          <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            contentLabel="Complitech Client Testimonials"
          >
            <div ref={_subtitle => (subtitle = _subtitle)} className="videoWrapper">
              <iframe title="Testimonial" width="100%" height="auto" src={props.testimonialVideo} frameborder="0" allow="accelerometer; autoplay; picture-in-picture" allowfullscreen></iframe>
              <div className="closeButtonModal" onClick={closeModal}>
                <img src={ModalClose} alt="testimonial-thumbnail" className="w-100" />
              </div>
            </div>
          </Modal>
        </div>
      </Container>
    </Container>
  );
}

export default Testimonial;