import React from 'react';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  Button,
  Container,
  NavItem,
  Row,
  Col,
} from 'reactstrap';

import { NavLink } from 'react-router-dom'

import ResponsiveNav from './responsiveNav'

import Headroom from 'react-headroom';
import HireUsModal from './hire_us_modal'

const links = [
  { to: '/work', text: 'Work', className: 'work_nav'},
  { to: '/service', text: 'Service', className: 'service_nav' },
  { to: '/about', text: 'About', className: 'about_nav' },
  { to: '/careers', text: 'Careers', className: 'careers_nav' },
  // { to: '/contact', text: 'Contact', className: 'contact_nav' },
  { to: '/blog', text: 'Ideas', className: 'ideas_nav' },
  // { to: '/', text: 'Adds', className: 'btnadd' },
];

const createNavItem = ({ to, text, className }) => (
  <NavItem key={text}>
    <NavLink to={to} activeClassName="active" className={className}>{text}</NavLink>
  </NavItem>
);

export default class Navigation extends React.Component {  
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
   
    this.state = {
      isOpen: false,
      displayModal : false,

      prevScrollpos: window.pageYOffset,
      visible: !this.props.white,
    };
    
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    if (!this.props.white) window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount
  componentWillUnmount() {
    if (!this.props.white)
      window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu
  handleScroll = () => {
    const currentScrollPos = window.pageYOffset;
    
    if (currentScrollPos <= 75) {
      this.setState({ visible: true });
    } else if (currentScrollPos >= 75) {
      this.setState({ visible: false });
    }
  };
  
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  triggerredModal = () => {
    const {displayModal} = this.state;
    this.setState({
      displayModal: !displayModal
    })
  }

  render() {
    return (
      <Headroom>
        <Navbar
          light
          expand="md"
          
          className={'d-none d-lg-block is-stuck ' + !this.state.visible }
        >
          <Container fluid className="navigation-menu">
            <NavLink to="/" className="navbar-brand" exact>
              <svg 
                xmlns="http://www.w3.org/2000/svg" id="Layer_1" 
                data-name="Layer 1"
                viewBox="0 0 302.82 478.49">
                
                <title>shape</title>
                <path d="M274.53,74.2s0,46-35.39,77.61c-17.88,16-38.85,33.58-77.11,33.58V312.28s44.92,1.33,76.56,34.36c18.09,18.88,33.58,38.3,35.94,79.16L338,362.36s-48.32,9.81-81.62-29.32c-15.77-18.53-32.47-42.19-30.88-84.21,0,0-2.42-48.17,27.27-82,15.53-17.7,35.34-39,85.23-29.16Z" transform="translate(-98.59 -10.76)" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="63.44" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="415.05" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="63.44" r="63.44" style={{fill: '#fff'}}/>
              </svg>
            </NavLink>

            <NavbarToggler onClick={this.toggle} className={'' + !this.state.isOpen } />
            <Collapse navbar isOpen={this.state.isOpen}>
              <Nav navbar>
                {links.map(createNavItem)}
              </Nav>
                        
              <Nav navbar className="ml-auto">
                <li>
                  <Button color="black" size="lg" className="align-self-center" onClick={this.triggerredModal}>Let's talk</Button>
                </li>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
        <HireUsModal onRef={(ref) => (this.parentRef = ref)}	parentRef={this.triggerredModal.bind(this)} displayModal={this.state.displayModal}/>
        <Container fluid className={'d-lg-none toggler-button py-3 is-stuck ' + !this.state.visible}>
          <Row>
            <Col size="12" className="text-right">
              <ResponsiveNav />
            </Col>
          </Row>
        </Container>

      </Headroom>
      
    );
  }
}
