import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap';

import SectionTitle, {SectionSubTitle} from '../../components/sectionTitle'

import {
  IndustriesIcon01,
  IndustriesIcon02,
  IndustriesIcon03,
  IndustriesIcon04,
  IndustriesIcon05,
  IndustriesIcon06,
  IndustriesIcon07,
  IndustriesIcon08,
  IndustriesIcon09,
  IndustriesIcon10,
  IndustriesIcon11,
  IndustriesIcon12,
  IndustriesIcon13,
  IndustriesIcon14
} from './IndustriesIcons'


function WeTakeCare(props){
  return(
    <Container fluid className="py-5" id="our_work">
      <Container>
        <Row>
          <Col lg="12">
            
            {/*<SectionTitle title="We Take Care of You at Every Step" color="black" />
            
            <ol className="mt-5">
              <li><LargeText title={<>We help our clients <span className="highlight" key="1">make business decisions</span> based on the best possible information. That’s why we do our best to <span className="highlight" key="2">figure out the budget</span> as early as possible.</>} /></li>

              <li><LargeText title={<><span className="highlight blue" key="3">Magic happens</span> when <span className="highlight blue" key="4">design and code</span> come to the scene. And we do it agile- because we are keen to show what we have done, we welcome feedback and strive to make it better.</>} /></li>

              <li><LargeText title={<>Every solution has bugs and we are here to make it <span className="highlight green" key="5">bug-free.</span> We are honest about it and it’s our job to manage risks and fix issues before it’s ready to launch.</>} /></li>

              <li><LargeText title={<>When all the components <span className="highlight red" key="6">work together,</span> the final software/product becomes ready to go live.</>} /></li>
            </ol>

            <Row className="mb-5">
              <Col md="4">
                <SectionTitle title="We Take Care of You at Every Step" color="black" />
              </Col>
            </Row>*/}

            <Row className="mb-5">
              <Col md="5">
                <SectionTitle title="We Take Care of You at Every Step" color="black" />
              </Col>
              <Col md="7">
                <ul className="mb-4 mt-md-2 custom-bullets">
                  <li key="1">We help our clients make business decisions based on the best possible information. That’s why we do our best to figure out the budget as early as possible.</li>
                  <li key="2">Magic happens when design and code come to the scene. And we do it agile- because we are keen to show what we have done, we welcome feedback and strive to make it better.</li>
                  <li key="3">Every solution has bugs and we are here to make it bug-free. We are honest about it and it’s our job to manage risks and fix issues before it’s ready to launch.</li>
                  <li key="4">When all the components work together,the final software/product becomes ready to go live.</li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row> 

        <Row className="mt-5">
          <Col lg="12">
            <SectionTitle title="Developing Solutions" color="black" />
            <SectionSubTitle title="for a wide-range of business domains" color="black" />
          </Col>

          <Row className="text-center mt-5 w-100">
            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon01 />
                <h6>Transport</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon02 />
                <h6>Computer</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon03 />
                <h6>Telecommunication</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon04 />
                <h6>Education</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon05 />
                <h6>Pharmaceutical</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon06 />
                <h6>Food</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon07 />
                <h6>Health care</h6>
              </div>
            </Col>          
          </Row>

          <Row className="text-center mt-3 w-100">
            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon08 />
                <h6>Hospitality</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon09 />
                <h6>Entertainment</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon10 />
                <h6>Music</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon11 />
                <h6>Insurance</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon12 />
                <h6>Advertising</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon13 />
                <h6>Fashion</h6>
              </div>
            </Col>

            <Col className="mb-xl-4 px-2">
              <div className="industries py-3 px-2">
                <IndustriesIcon14 />
                <h6>Retail</h6>
              </div>
            </Col>
          </Row>
        </Row>        
      </Container>
    </Container>
  );
}

export default WeTakeCare;