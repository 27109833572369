import React from 'react';

import { Link } from 'react-scroll';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import {
  CSPLteam,
  CultureBG,
  Growth
} from '../../components/assets';

import SectionTitle, { SectionSubTitle } from '../../components/sectionTitle';
import { LargeText } from '../../components/projectTitle';
import {BlogQuote} from '../blogs/blogPostTags';

import {
  TeamQualityIcon01,
  TeamQualityIcon02,
  TeamQualityIcon03,
  TeamQualityIcon04,
  TeamQualityIcon05,
  TeamQualityIcon06
} from './teamQualityIcons'

function CareerContents(){
  return(
    <Row className="mx-0">
      <Container fluid className="bg-light py-5">
        <Container>
          <img src={CSPLteam} alt="CSPL Team Background" className="img-fluid" />
          <SectionSubTitle color="yellow" className="mt-5 mb-4" title="Determine how your job can help us change lives, show us how it’s done, and take charge of your career." />

          <LargeText title="We are hunting for outstanding individuals who are driven by a passion for excellence. Our leadership has the vision to provide a culture that challenges you more and pushes you to go beyond your limits." />
          
          <Row>
            <Col className="text-center">
              <Link to="vacancy" smooth={true} offset={-70} duration={500}>
                <Button color="black" size="lg">See Current Openings</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </Container>

      <Container>
        <Row className="flex-lg-row-reverse py-5 d-flex align-items-center">
          <Col lg="6">
            <img src={CultureBG} alt="Culture background" className="img-fluid" />
          </Col>

          <Col lg="6" className="mt-4 mt-lg-0">
            <SectionTitle title="Our Culture"/>
            <SectionSubTitle title={["At Complitech, we believe that without each other we are nothing. ", <span className="highlight green" key="1">Together we are everything.</span>]} className="my-4"/>
            <LargeText title="More than anything else, we genuinely like each other. That goes a long way. Our clients feel the positive energy of our culture. Our happy people work hard and push each other to grow every day, and high quality of our work reflects that." />
          </Col>
        </Row>
      </Container>

      <Container fluid className="py-5">
        <Container>
          <Row className="d-flex align-items-center">
            <Col lg="6">
              <img src={Growth} alt="Culture of Growth" className="img-fluid" />
            </Col>

            <Col lg="6" className="px-0 px-lg-4 mt-4 mt-lg-0">
              <SectionSubTitle title="Culture of Growth, Ongoing Improvement and Success." className="mb-4"/>
              <LargeText className="mb-0" title="At Complitech, we understand TRAINING and DEVELOPMENT as key factors for personal and professional satisfaction of our employees. We define such programs for our employees to carry out the best versions of themselves to encourage a culture of growth and ongoing improvement that allows them to take on new responsibilities in future." />
            </Col>
          </Row>
        </Container>
      </Container>

      <Container fluid className="py-5">
        <Container>
          <Row>
            <Col lg="12">
              <SectionTitle title="Why Join us?" />
              <SectionSubTitle title="A great team to work with..." className="mt-4"/>
            </Col>
          </Row>
          <Row>
            <Col>
              <BlogQuote size="md" title={["If the employees come first, then they're happy. A motivated employee treats the customer well. The customer is happy so they keep coming back, which pleases the shareholders. It's not one of the enduring green mysteries of all time, it is just the way it works.", <span className="author" key="1">Herb Kelleher</span>]} />
            </Col>
          </Row>

          <Row className="text-center mt-5">
            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon01 />
                <h2>Shape your career</h2>
              </div>
            </Col>

            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon02 />
                <h2>Learn and grow</h2>
              </div>
            </Col>

            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon03 />
                <h2>Continuous Improvement</h2>
              </div>
            </Col>

            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon04 />
                <h2>Teamwork</h2>
              </div>
            </Col>

            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon05 />
                <h2>Stay healthy</h2>
              </div>
            </Col>

            <Col sm="6" md="4" className="mb-xl-4">
              <div className="team-quality py-5">
                <TeamQualityIcon06 />
                <h2>Invest in yourself</h2>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </Row>
  );
}

export default CareerContents;