import React from 'react';

import PageBanner from '../../components/pageBanner';

import WhatweOffer from './whatweOffer';
import Services from './serviceSections';
import HowWeDo from './howwedo';
import WeTakeCare from './wetakecare';
import Languages from './languages';
import Testimonials from '../../components/testimonial';

import GetinTouch from '../../components/getinTouch';

import {TestimonialShane, ServiceBannerBG} from '../../components/assets';

function Service(props){
  return(
      <div>
        <PageBanner 
          bannerImage={ServiceBannerBG}
          bannerImageAlt="What Complitech can offer for your business?" 
          title={<>We do our best in <span className="highlight">What We Do</span></>}
          description="We build innovative digital solutions and deep industry expertise to ignite digital transformation across enterprises." />
        <WhatweOffer />
        <Services />
        <HowWeDo />
        <Languages />
        <WeTakeCare />
        <Testimonials
          testimonialImage={TestimonialShane}
          testimonialImageAlt="testimonial-shane"
          title="My favourite thing personally about working with Complitech, is the flexibility and their desire to get the job done right. They are very good at communicating with us. And we were extremely satisfied with our work with them."
          testimonialVideo="https://www.youtube-nocookie.com/embed/8P3txmIdUMU?autoplay=1;rel=0"
        />
        <GetinTouch />
      </div>
    );
  }
export default Service;