import React, { useState } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  Nav,
  NavItem,
  Col,
  Row
} from 'reactstrap';

import { NavLink } from 'react-router-dom'

const links = [
  { to: '/work', text: 'Work' },
  { to: '/service', text: 'Service' },
  { to: '/about', text: 'About' },
  { to: '/careers', text: 'Careers' },
  { to: '/contact', text: 'Contact' },
  { to: '/blog', text: 'Ideas' },
];

const createNavItem = ({ to, text, className }) => (
  <NavItem key={text}>
    <NavLink to={to} className={className} >{text}</NavLink>
  </NavItem>
);

const ResponsiveNav = (props) => {
  
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <Row>
        <Col size="6" className="navigation-menu">
          <NavLink to="/" className="navbar-brand" exact>
            <svg 
              xmlns="http://www.w3.org/2000/svg" id="Layer_1" 
              data-name="Layer 1"
              viewBox="0 0 302.82 478.49">
              
              <title>shape</title>
              <path d="M274.53,74.2s0,46-35.39,77.61c-17.88,16-38.85,33.58-77.11,33.58V312.28s44.92,1.33,76.56,34.36c18.09,18.88,33.58,38.3,35.94,79.16L338,362.36s-48.32,9.81-81.62-29.32c-15.77-18.53-32.47-42.19-30.88-84.21,0,0-2.42-48.17,27.27-82,15.53-17.7,35.34-39,85.23-29.16Z" transform="translate(-98.59 -10.76)" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="63.44" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="415.05" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="63.44" r="63.44" style={{fill: '#fff'}}/>
            </svg>
          </NavLink>

          <NavLink to="/" className="navbar-brand close-menu" exact onClick={toggle}>
            <svg 
              xmlns="http://www.w3.org/2000/svg" id="Layer_1" 
              data-name="Layer 1"
              viewBox="0 0 302.82 478.49">
              
              <title>shape</title>
              <path d="M274.53,74.2s0,46-35.39,77.61c-17.88,16-38.85,33.58-77.11,33.58V312.28s44.92,1.33,76.56,34.36c18.09,18.88,33.58,38.3,35.94,79.16L338,362.36s-48.32,9.81-81.62-29.32c-15.77-18.53-32.47-42.19-30.88-84.21,0,0-2.42-48.17,27.27-82,15.53-17.7,35.34-39,85.23-29.16Z" transform="translate(-98.59 -10.76)" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="63.44" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="415.05" r="63.44" style={{fill: '#fff'}}/>
              <circle cx="239.38" cy="63.44" r="63.44" style={{fill: '#fff'}}/>
            </svg>
          </NavLink>
        </Col>

        <Col size="6">
          <Button onClick={toggle} className="hamburger" color="link">
            <span className="line1"></span>
            <span className="line2"></span>
          </Button>
        </Col>
      </Row>

      
      <Modal isOpen={modal} toggle={toggle} className="responsive-navbar">
        <ModalBody>
          <div className="navigation-menu nav-small d-none">
            <NavLink to="/" className="navbar-brand" exact>
              <svg 
                xmlns="http://www.w3.org/2000/svg" id="Layer_1" 
                data-name="Layer 1"
                viewBox="0 0 302.82 478.49">
                
                <title>shape</title>
                <path d="M274.53,74.2s0,46-35.39,77.61c-17.88,16-38.85,33.58-77.11,33.58V312.28s44.92,1.33,76.56,34.36c18.09,18.88,33.58,38.3,35.94,79.16L338,362.36s-48.32,9.81-81.62-29.32c-15.77-18.53-32.47-42.19-30.88-84.21,0,0-2.42-48.17,27.27-82,15.53-17.7,35.34-39,85.23-29.16Z" transform="translate(-98.59 -10.76)" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="63.44" cy="238.08" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="415.05" r="63.44" style={{fill: '#fff'}}/>
                <circle cx="239.38" cy="63.44" r="63.44" style={{fill: '#fff'}}/>
              </svg>
            </NavLink>
          </div>
          <Nav navbar onClick={toggle}>
            {links.map(createNavItem)}
            <NavLink to="/hire-us">
              <Button size="lg" color="black" className="mt-4">Hire Us</Button>
            </NavLink>
          </Nav>
        </ModalBody>
      </Modal>
    </div>
  );
}

export default ResponsiveNav;