import React from 'react';

import { Helmet } from 'react-helmet-async';

import PageBanner from '../../components/pageBanner';

import WorkPost from './workPost';

import Testimonials from '../../components/testimonial';

import GetinTouch from '../../components/getinTouch';

import {TestimonialJosiah, WorkBannerBG} from '../../components/assets';

function Work(props){
  return(
      <div>
        <Helmet>
          <title>Complitech - Web and Mobile App development work - that we're proud of.</title>
          <meta property="og:title" content="Complitech - Web and Mobile App development work - that we're proud of." />
          <meta property="og:image" content={WorkBannerBG} />
          <meta property="og:type" content="page" />
          <meta property="og:description" content="We've delivered solutions that have helped businesses and that's why our clients refer &amp; recommend us!" />
          <meta name="description" content="We've delivered solutions that have helped businesses and that's why our clients refer &amp; recommend us!" />
        </Helmet>
        <PageBanner
          bannerImage={WorkBannerBG} 
          bannerImageAlt="Web and Mobile App development work - that we're proud of." 
          title={<>Check out some of our <span className="highlight blue" key="1">Awesome Work</span></>}
          description="We've delivered solutions that have helped businesses and that's why our clients refer &amp; recommend us!"
        />
        <WorkPost />
        <Testimonials
          testimonialImage={TestimonialJosiah}
          testimonialImageAlt="testimonial-josiah"
          title="I had a good friend refer me to them who’s been very very happy with their services. And now I understand why. They really have a great team, very responsive, easy to communicate with, a fast responsive and attentive to all of our needs that we’ve had. Our relationship has really gotten better and better since we’ve started working with them."
          testimonialVideo="https://www.youtube-nocookie.com/embed/sqVrIxGa8J8?autoplay=1;rel=0"
        />
        <GetinTouch />
      </div>
    );
  }
  export default Work;