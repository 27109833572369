import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Router, Route, Switch } from 'react-router-dom';

import Navigation from './components/navigation';
import DynamicScrollToTop from './components/scrollTop';
import Footer from './components/footer';
import Error from './components/error';

import Home from './pages/home/home';
import Service from './pages/service/service';
import About from './pages/about/about';
import Contact from './pages/contact/contact';
import Work from './pages/work/work';
import Blogs from './pages/blogs/blogs';
import Career from './pages/career/career';
import HireUs from './pages/hire/hire-us';
import Privacy from './pages/privacy/privacy';
import Terms from './pages/terms/terms';

// Thank You pages
import ThankYouCandidate from './components/thankYouCandidate';
import ThankYouLead from './components/thankYouLead';

// Case Studies Links
import CSTriviago from './pages/caseStudies/csTriviago';
import CSMobiprint from './pages/caseStudies/csMobiprint';

// Blog Categories
import Productivity from './blogs/productivity/productivity';
import UIUX from './blogs/uiux/uiux';
import Technical from './blogs/technical/technical';
import Business from './blogs/business/business';

// Blog Links
import BlogStayFocused from './blogs/productivity/blogStayFocused';
import BlogWireframing from './blogs/uiux/blogWireframing';
import BlogEcommerce from './blogs/business/blogEcommerce';
import BlogAppstore from './blogs/technical/blogAppstore';
import BlogMobileapp from './blogs/technical/blogMobileapp';

import Aosa from './pages/work/aosa';
import Limocious from './pages/work/limocious';
import Invoease from './pages/work/invoease';
import VirtualInsuranceTools from './pages/work/vit';
import BPNMaestro from './pages/work/bpnmaestro';
import NearBuyNZ from './pages/work/nearbuy';
import HenslerSurgical from './pages/work/hensler';

// Vacancies Link
import AndroidDeveloper from './pages/career/vacancies/androidDeveloper';

// Files for Google Analytics Page Tracking
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';

const trackingId = "UA-35179386-1";
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

// Initialize google analytics page view tracking
history.listen((location) => {
  // let location = useLocation();
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const helmetContext = {};

export default class App extends Component {

  renderWithHeaderFooter(component) {
    return <><Navigation />{component}<Footer /></>
  }

  render() {
    return (
      <HelmetProvider context={helmetContext}>
        <Router history={history}>
          <div className="App">
            <Helmet>
              <title>Complitech 2020 Site</title>
              <meta charSet="utf-8" />
              <meta name="description" content="Complitech 2020 new brand site" />
            </Helmet>
            <DynamicScrollToTop />
            <Switch>
              <Route path="/" render={props => this.renderWithHeaderFooter(<Home {...props}/>)} exact />
              <Route path="/service" render={props => this.renderWithHeaderFooter(<Service {...props}/>)} exact />
              <Route path="/about" render={props => this.renderWithHeaderFooter(<About {...props}/>)} exact />
              <Route path="/contact" render={props => this.renderWithHeaderFooter(<Contact {...props}/>)} exact />
              <Route path="/work" render={props => this.renderWithHeaderFooter(<Work {...props}/>)} exact />

              <Route path="/careers" render={props => this.renderWithHeaderFooter(<Career {...props}/>)} exact />
              <Route path="/hire-us" render={props => this.renderWithHeaderFooter(<HireUs {...props}/>)} exact />
              <Route path="/blog" render={props => this.renderWithHeaderFooter(<Blogs {...props}/>)} exact />
              <Route path="/thankyou_for_inquiry" render={props => this.renderWithHeaderFooter(<ThankYouLead {...props}/>)} exact />
              <Route path="/thankyou_for_applying" render={props => this.renderWithHeaderFooter(<ThankYouCandidate {...props}/>)} exact />
              
              {/* Privacy & Terms */}
              <Route path="/privacy-policy" render={props => this.renderWithHeaderFooter(<Privacy {...props}/>)} exact />
              <Route path="/terms-conditions" render={props => this.renderWithHeaderFooter(<Terms {...props}/>)} exact />
              
              {/* Case Studies Links */}
              <Route path="/work/triviago" render={props => this.renderWithHeaderFooter(<CSTriviago {...props}/>)} exact />
              <Route path="/work/mobi-print" render={props => this.renderWithHeaderFooter(<CSMobiprint {...props}/>)} exact />

              {/* Blog Categories Links */}
              <Route path="/productivity" render={props => this.renderWithHeaderFooter(<Productivity {...props}/>)} exact />
              <Route path="/ui-ux" render={props => this.renderWithHeaderFooter(<UIUX {...props}/>)} exact />
              <Route path="/technical" render={props => this.renderWithHeaderFooter(<Technical {...props}/>)} exact />
              <Route path="/business" render={props => this.renderWithHeaderFooter(<Business {...props}/>)} exact />

              {/* Blog Links */}
              <Route path="/productivity/stay-focused-in-a-world-full-of-distractions" render={props => this.renderWithHeaderFooter(<BlogStayFocused {...props}/>)} exact />
              <Route path="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important" render={props => this.renderWithHeaderFooter(<BlogWireframing {...props}/>)} exact />
              <Route path="/business/e-commerce-in-covid-19" render={props => this.renderWithHeaderFooter(<BlogEcommerce {...props}/>)} exact />
              <Route path="/technical/common-reasons-why-app-store-rejects-apps-how-to-avoid-them" render={props => this.renderWithHeaderFooter(<BlogAppstore {...props}/>)} exact />
              <Route path="/technical/important-tips-to-know-before-developing-a-mobile-app" render={props => this.renderWithHeaderFooter(<BlogMobileapp {...props}/>)} exact />
              
              <Route path="/work/aosa" render={props => this.renderWithHeaderFooter(<Aosa {...props}/>)} exact />
              <Route path="/work/limocious" render={props => this.renderWithHeaderFooter(<Limocious {...props}/>)} exact />
              <Route path="/work/invoease" render={props => this.renderWithHeaderFooter(<Invoease {...props}/>)} exact />
              <Route path="/work/virtualinsurancetools" render={props => this.renderWithHeaderFooter(<VirtualInsuranceTools {...props}/>)} exact />
              <Route path="/work/bpnmaestro" render={props => this.renderWithHeaderFooter(<BPNMaestro {...props}/>)} exact />
              <Route path="/work/nearbuynz" render={props => this.renderWithHeaderFooter(<NearBuyNZ {...props}/>)} exact />
              <Route path="/work/henslersurgical" render={props => this.renderWithHeaderFooter(<HenslerSurgical {...props}/>)} exact />

              {/* Vacancies Links */}
              <Route path="/careers/android-developer" render={props => this.renderWithHeaderFooter(<AndroidDeveloper {...props}/>)} exact />

              <Route render={props => this.renderWithHeaderFooter(<Error {...props}/>)} />
            </Switch>
          </div>
        </Router>
      </HelmetProvider>
    );
  }
}

ReactDOM.hydrate(
  App,
  document.getElementById('root')
);