import React from 'react';

import { NavLink } from 'react-router-dom';

import {
  Card,
  CardImg,
  CardBody,
} from 'reactstrap';

import BlogPostTitle, {BlogPostDate, BlogPostText} from './blogPostTags'

import {StayFocused, Wireframing, Ecommerce, Appstore, Mobileapp} from '../../components/assets';

const ImportantTipsToKnowBeforeDevelopingMobileApp = (props) => {
  return(
    <NavLink to="/technical/important-tips-to-know-before-developing-a-mobile-app">
      <Card>
        <CardImg
          top
          src={Mobileapp}
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title="Important Tips to know before Developing a Mobile App" />
          <BlogPostDate title="Technical • 4 min read" />
          <BlogPostText title="The rising popularity of smartphones and tablets has given many businesses new ways to connect or engage with their......." />
        </CardBody>

      </Card>
    </NavLink>
  );
}

const CommonReasonsWhyAppStoreRejectsAppsAndHowToAvoidThem = (props) => {
  return(
    <NavLink to="/technical/common-reasons-why-app-store-rejects-apps-how-to-avoid-them">
      <Card>
        <CardImg
          top
          src={Appstore}
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title="Common reasons why App Store rejects Apps & How to avoid them" />
          <BlogPostDate title="Technical • 4 min read" />
          <BlogPostText title="With almost 1.84 million apps available in the iOS app store as of 4th quarter 2019, entering such a crowded market......" />
        </CardBody>

      </Card>
    </NavLink>
  );
}

const EcommerceInCOVID19 = (props) => {
  return(
    <NavLink to="/business/e-commerce-in-covid-19">
      <Card>
        <CardImg
          top
          src={Ecommerce}
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title="E-commerce in COVID 19" />
          <BlogPostDate title="Business • 4 min read" />
          <BlogPostText title="Across the world, where COVID-19 has made an impact, isolation, quarantine and social distancing measures have been put in place...." />
        </CardBody>

      </Card>
    </NavLink>
  );
}

const WhatIsWireframeWhyIsWireframingSoImportant = (props) => {
  return(
    <NavLink to="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important">
      <Card>
        <CardImg
          top
          src={Wireframing}
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title="What is a Wireframe? Why is wireframing so important?" />
          <BlogPostDate title="UI/UX • 4 min read" />
          <BlogPostText title="Wireframing is one of the most important and first steps in your design process. It’s the time
          when you draw...." />
        </CardBody>

      </Card>
    </NavLink>
  );
}

const StayFocusedInWorldFullOfDistractions = (props) => {
  return(
    <NavLink to="/productivity/stay-focused-in-a-world-full-of-distractions">
      <Card>
        <CardImg
          top
          src={StayFocused}
          width="100%"
        />
        
        <CardBody>
          <BlogPostTitle title="Stay focused in a world full of distractions" />
          <BlogPostDate title="Productivity • 4 min read" />
          <BlogPostText title="In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks...." />
        </CardBody>

      </Card>
    </NavLink>
  );
}

export {
  ImportantTipsToKnowBeforeDevelopingMobileApp,
  CommonReasonsWhyAppStoreRejectsAppsAndHowToAvoidThem,
  EcommerceInCOVID19,
  WhatIsWireframeWhyIsWireframingSoImportant,
  StayFocusedInWorldFullOfDistractions
};