import React from 'react';
import{
  Container,
  Row,
  Col
} from 'reactstrap';

import { NavLink } from 'react-router-dom'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fab)

function Home(){
  return(
    <Container fluid className="footer p-3 p-md-4 p-lg-5">
      <Container className="mt-0 mt-md-4 mt-lg-5">
        <Row>
          <Col md="3">
            <h3>Explore</h3>
            <Row>
              <Col>
                <ul className="pl-0">
                  <li><NavLink to="/work">Work</NavLink></li>
                  <li><NavLink to="/service">Service</NavLink></li>
                  <li><NavLink to="/about">About</NavLink></li>
                </ul>
              </Col>
              <Col>
                <ul className="pl-0">
                  <li><NavLink to="/careers">Careers</NavLink></li>
                  <li><NavLink to="/contact">Contact</NavLink></li>
                  <li><NavLink to="/blog">Ideas</NavLink></li>
                </ul>
              </Col>
              <Col></Col>
            </Row>
          </Col>

          <Col md="3">
            <h3>Blog</h3>
            <ul className="pl-0">
              <li><NavLink to="/business/e-commerce-in-covid-19">E-commerce in COVID 19</NavLink></li>
              <li><NavLink to="/productivity/stay-focused-in-a-world-full-of-distractions">Stay Focused</NavLink></li>
              <li><NavLink to="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important">What is a Wireframe?</NavLink></li>
            </ul>
          </Col>

          {/* <Col lg="3" md="6">
            <h3>New York</h3>
            <p>
              Judge st, Elmhurst, <br key="1"/>
              New York, USA.
            </p>

            <h3>India</h3>
            <p>
              Bhuyangdev Cross <br key="3"/>
              Roads, Ghatlodia, <br key="1"/>
              Ahmedabad, INDIA.
            </p>
          </Col> */}

          {/*<Col lg="3" md="6">
            <Row>
              <Col md="12">
                <h3>Apply for Job</h3>
                <a href="mailto:hr@complitech.us">hr@complitech.us</a>
                <a href="tel:+917567467777">+91 756 746 7777</a>
              </Col>
            </Row>
          </Col>*/}

          <Col md="3" className="d-none d-md-block"></Col>

          <Col md="3">
            <Row>
              <Col md="12" className="text-lg-right">
                <h3>Connect</h3>
                <a href="tel:+17188199223">+1 718 819 9223</a>
                <a href="mailto:nik@complitech.us">nik@complitech.us</a>
              </Col>
            </Row>

            <Row>
              <Col md="12" className="social-connect text-lg-right">                
                <a href="https://www.facebook.com/complitech" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'facebook-f']}/>
                </a>
                <a href="https://www.linkedin.com/company/complitech-solutions/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'linkedin-in']}/>
                </a>
                <a href="https://www.instagram.com/complitech/" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'instagram']}/>
                </a>
                <a href="https://www.youtube.com/complitech" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={['fab', 'youtube']}/>
                </a>
              </Col>
            </Row>
          </Col>

        </Row>
      </Container>

      {/*<Container className="sub-footer pt-2 mt-4 mb-0 mb-md-4 mb-lg-5">
        <Row>
          <Col md="9">
            <p>Complitech is a premier web design and web development company with offices in New York and India.</p>
            <p>Complitech has been rated on Google 5 out of 5 by many reviewers and customers.</p>
            <p>Complitech Reimagining digital experiences since 2003</p>
          </Col>

          <Col md="3" className="text-left mt-4 mt-md-0">
            <p>&copy; 2020 Complitech</p>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/terms-conditions">Terms & Conditions</NavLink>
          </Col>
        </Row>
      </Container>*/}

      <Container className="sub-footer pt-2 mt-4">
        <Row>
          <div className="text-left mt-4 mt-md-0">
            <ul className="pl-0">
              <li className="text-white">&copy; 2020 Complitech</li>
              <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
              <li><NavLink to="/terms-conditions">Terms & Conditions</NavLink></li>
            </ul>
          </div>
        </Row>
      </Container>
    
    
    </Container>
  );
}
export default Home;