import React from 'react'

import{
  Container,
  Row,
  Col,
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle'

import ExpertiseTitle, {ExpertiseText} from '../../components/expertiseComponents';

function Communication(){
  return(
    <Container fluid className="py-5">
      <Container>
        <SectionTitle title="How we do it?" color="black" />

        <Row className="mx-0 mt-4">
          <Col lg="4" className="expertise p-4 p-md-5">
            <ExpertiseTitle title="Communication" />
            <ExpertiseText title="We believe in Communicating more. Conversation can solve most problems. At every step, you will know what to expect and what to do next. Honest  communication binds us together. For  us, you’re a partner, not just a client." />
          </Col>

          <Col lg="4" className="expertise p-4 p-md-5">
            <ExpertiseTitle title="Professionalism" />
            <ExpertiseText title="To us, professionalism doesn’t mean you need to wear a business suit. It’s about having the will, desire and commitment to be your best. It actually means doing what we say and saying what we mean." />
          </Col>

          <Col lg="4" className="expertise p-4 p-md-5">
            <ExpertiseTitle title="Community" />
            <ExpertiseText title="Communication leads to community, that is, to  understanding, intimacy and mutual valuing. As developers, we go out of our way to find the best ways possible we can serve." />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Communication;