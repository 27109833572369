import React from 'react'

import{
  Container,
  Row,
  Col,
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle'

import ExpertiseTitle from '../../components/expertiseComponents';

import {Photoshop, Illustrator, AfterEffects, Sketch, Invision, Flutter, ReactNative, Kotlin, Swift, ObjectiveC, RubyOnRails, Laravel, Wordpress, Shopify} from '../../components/assets'

function Languages(){
  return(
    <Container fluid className="py-5 bg-light">
      <Container>        
        <SectionTitle title="Languages, Tools and Frameworks" color="black" />
        <p>Whatever the field or platform, we make it happen  </p>

        <Row className="mx-0 mt-4">
          <Col lg="4" className="howwedo p-4 p-md-5">
            <ExpertiseTitle title={<><span className="highlight">DESIGN</span></>} />
            <div className="mt-2">
              <div className="media">
                <img src={Photoshop} alt="Photoshop" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-1">Photoshop</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={Illustrator} alt="Illustrator" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Illustrator</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={AfterEffects} alt="After Effects" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">After Effects</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={Sketch} alt="Sketch" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Sketch</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={Invision} alt="Invision" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Invision</h3>
                </div>
              </div>
            </div>
          </Col>


          <Col lg="4" className="howwedo p-4 p-md-5">
            <ExpertiseTitle title={<><span className="highlight">MOBILE</span></>} />
            <div className="mt-2">
              <div className="media">
                <img src={Flutter} alt="Flutter" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Flutter</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={ReactNative} alt="React Native" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">React Native</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={Kotlin} alt="Kotlin" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Kotlin</h3>
                </div>
              </div>
            </div>            

            <div className="mt-4">
              <div className="media">
                <img src={Swift} alt="Swift" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Swift</h3>
                </div>
              </div>
            </div>            

            <div className="mt-4">
              <div className="media">
                <img src={ObjectiveC} alt="Objective C" className="img-fluid mr-3" style={{width: '40px', height: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Objective C</h3>
                </div>
              </div>
            </div>
          </Col>


          <Col lg="4" className="howwedo p-4 p-md-5">
            <ExpertiseTitle title={<><span className="highlight">WEB</span></>} />
            <div className="mt-2">
              <div className="media">
                <img src={RubyOnRails} alt="Ruby on Rails" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-1">Ruby on Rails</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={ReactNative} alt="React.js" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">React.js</h3>
                </div>
              </div>
            </div>

            <div className="mt-4">
              <div className="media">
                <img src={Laravel} alt="Laravel" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-1">Laravel</h3>
                </div>
              </div>
            </div>            

            <div className="mt-4">
              <div className="media">
                <img src={Wordpress} alt="Wordpress" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Wordpress</h3>
                </div>
              </div>
            </div>            

            <div className="mt-4">
              <div className="media">
                <img src={Shopify} alt="Shopify" className="img-fluid mr-3" style={{width: '40px'}} />
                <div className="media-body">
                  <h3 className="mb-0 pb-0 mt-2">Shopify</h3>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Languages;