import React from 'react';

import PageBanner from '../../components/pageBanner';

import HireusForm from './hireusForm';

import GetinTouch from '../../components/getinTouch';

import {HireusBannerBG} from '../../components/assets';

function HireUs(props){
  return(
      <div>
        <PageBanner 
          bannerImage={HireusBannerBG} 
          bannerImageAlt="Hire Complitech" 
          title={<>Success starts with <br/>a step, <span className="highlight" key="1">Let’s do it!</span></>}
          description="You are not just hiring us, you're hiring our trust too. Want to see how Complitech can take your business even further?"
        />
        <HireusForm />
        <GetinTouch />
      </div>
    );
  }
  export default HireUs;