import React from 'react';

import BlogsBanner from './blogsBanner';

import BlogPost from './blogPost';

function Blogs(){
  return(
      <div>
        <BlogsBanner />
        <BlogPost />
      </div>
    );
  }
  export default Blogs;