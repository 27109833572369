import React, { Component } from 'react'

import PageBanner from '../components/pageBanner';

import { ThankYou } from '../components/assets';

export default class ThankYouCandidate extends Component {

	render(){
		return(
			<PageBanner
        bannerImage={ThankYou}
        bannerImageAlt="Thank You" 
        title={<><span className="highlight red" key="1">Thank You</span>!<br/><span className="section-subtitle">We’ve received your information. <span role="img" aria-labelledby="jsx-a11y/accessible-emoji">👍🏻</span></span></>}
        description="We’re on it. And, our team will get back to you at the earliest."
      />
		)
	}
}