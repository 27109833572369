import React from 'react'

import { Helmet } from 'react-helmet-async';

// Page Progressbar
import PageProgress from 'react-page-progress'

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {Ecommerce} from '../../components/assets';

import SectionTitle from '../../components/sectionTitle';

import {BlogPostDate, BlogQuote ,BlogHashTags, PrevBlog, NextBlog} from '../../pages/blogs/blogPostTags';

import { LargeText } from '../../components/projectTitle';

import Sidebar, {Bottombar} from '../../pages/blogs/sidebar';

import{
  WhatIsWireframeWhyIsWireframingSoImportant,
  StayFocusedInWorldFullOfDistractions
} from '../../pages/blogs/blogList';

function BlogEcommerce() {
  return(<>
    <PageProgress color="var(--red)" height={3} className="page-progress-bar"/>
    
    <Container fluid className="px-0">
      <Helmet>
        <title>E-commerce</title>
        <meta property="og:type" content="article" />
        <meta name="description" content="E-commerce in COVID-19" />
      </Helmet>

      <img src={Ecommerce} alt="Ecommerce" className="img-fluid w-100" />

      <Container className="py-5 our-voice">
        <Row>
          {/* SIDEBAR */}
          <Col lg="3" className="blog-left-sidebar">
            <Sidebar
              categoryLink="/business"
              categoryName="Business"
              title="E-commerce in COVID 19"
            />
          </Col>
          
          {/* BLOG CONTENT */}
          <Col lg="9">
            <Row>
              <Col>
                <SectionTitle title="E-commerce in COVID 19" color="black" />
                <BlogPostDate title="4 min read" />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <BlogQuote size="lg" title={["People are gradually moving from offline shopping to online and the habit won’t disappear when the epidemic is over. This will have a very positive impact on the whole eCommerce industry.", <span className="author" key="1">Zhong Zhenshan, Vice-president of emerging technology research, IDC</span>]} />

                <p>Across the world, where COVID-19 has made an impact, isolation, quarantine and social distancing measures have been put in place. Factories and restaurants are closed, many small businesses are temporarily shut down, workers from many companies are asked to leave, a number of employees are working from home, many cities are placed under lockdown. Thus people in these countries have already started avoiding public places.</p>

                <p>But where many companies are facing a crisis during this pandemic, some small businesses are seeing a boom in their business and getting new customers. You’ve probably heard that eCommerce will grow remarkably due to the Coronavirus. In order to follow social distancing, people avoid going to stores and start using eCommerce websites. Clients also need to move to online retailing to sustain their businesses. Online stores for grocery, fast food, cosmetics, fashion, insurance will be in limelight.</p>

                <LargeText title="Millennials and Gen Xers are the biggest online  shoppers, with 67% of millennials and 56% of Gen Xers preferring to shop online versus in a brick-and-mortar store." />

                <p>China Academy of Information and Communications Technology has done the research. Research states that smartphone sales have been dropping constantly since December 2019 but eCommerce and delivery businesses are booming during the same period. In fact, the situation is the same all over the world. From this, we can predict that the digital side of the retail business will grow at a faster pace than in previous years.</p>

                <p>Research by IGD confirms that by 2023, the online grocery market will see a 66% turnover growth in Europe. Even in Asia, this market will reach 299 billion euros from 101 billion euros in 2018.</p>
                
                <p>Though it is a tough time for the world, for the business, but eCommerce is durable and will be in the market for long.</p>

                <BlogHashTags title="#ecommerce" />
                <BlogHashTags title="#covid-19" />
                <BlogHashTags title="#epidemic" />

              </Col>
            </Row>

          </Col>
        
          {/* BOTTOM BAR */}
          <Bottombar
            categoryLink="/business"
            categoryName="Business"
            title="E-commerce in COVID 19"
          />

        </Row>
      </Container>

      <Container>
        <Row className="next-prev-blog py-5">
          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'0'}} className="text-center text-lg-left mb-4 mb-lg-0">
            <PrevBlog
              to="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important"
              title="What is a Wireframe? Why is wireframing so important?"
            />
          </Col>

          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'4'}} className="text-center text-lg-right">
            <NextBlog
              to="/productivity/stay-focused-in-a-world-full-of-distractions"
              title="Stay focused in a world full of distraction"
            />
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-5 bg-light related-posts">
      <Container>
        <SectionTitle title="Related Posts" />

        <Row className="blog-post mt-5">
          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <StayFocusedInWorldFullOfDistractions />
          </Col>

          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <WhatIsWireframeWhyIsWireframingSoImportant />
          </Col>

        </Row>
      </Container>
    </Container>

  </>);

}

export default BlogEcommerce;