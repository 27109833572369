import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {limociousImage} from '../../components/assets'

const Limocious = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>

	    	<Row>
	    		<Col md="8">
	    			<img src={limociousImage} alt="Limocious" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="Limocious" />
		    			<WorkPostText className="mt-2 mb-3" title="Limocious connects you with every important aspect of your business. Be it your Passengers or Drivers, limocious gets you the freedom to monitor everything from a passenger's location to his destination and desired time for booking, it also helps you assign trips to your driver(s) take care of the entire process without having the need to make any phone calls!" />
		    			<WorkPostText className="mt-2 mb-3" title="Your passengers will love limocious for its features. Limocious comes with Preloaded Airport/Airlines, rate calculation and lot of more exciting features." />
		    			<WorkPostText className="mt-2 mb-3" title="Confirm/Reject/Dispatch all done from a single Admin Panel. Drivers can be tracked and managed, enable or disable push notifications all from one convenient app." />
		    			<WorkPostText className="mt-2 mb-3" title="Limocious supports Multiple User as administrators and is highly flexible. Fleet details, Terms & condition, Company information can be set as per your requirements." />
		    			<WorkPostText className="mt-2 mb-3" title="Limocious comes with Rate Calculation, Email and Push Notifications, Driver Photo and every other convenient aspect to make your passengers comfortable." />
		    			
              <NavLink to="https://apps.apple.com/us/app/limocious/id680546497" target="_blank">
                <Button color="black" size="lg" className="mb-3">Passengers App on App Store</Button>
              </NavLink>
		    			<NavLink to="https://apps.apple.com/us/app/limocious-chauffeur/id711327865" target="_blank">
                <Button color="black" size="lg" className="mb-3">Drivers App on App Store</Button>
              </NavLink>
		    			<NavLink to="http://limocio.us/" target="_blank">
                <Button color="black" size="lg" className="mb-3">Check out Limocious</Button>
              </NavLink>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default Limocious;