import React from 'react';

import {
  Container
  // Row,
  // Col,
  // Pagination,
  // PaginationItem,
  // PaginationLink
} from 'reactstrap';

import BlogPosts from './blogCategory';

const BlogPost = (props) => {
  return (
    <Container fluid className="bg-light py-5">
      <BlogPosts />

      {/* <Row className="text-center">
        <Pagination aria-label="Page navigation" className="mx-auto mt-5 blog-pagination">
          <PaginationItem>
            <PaginationLink first href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#" className="active">
              1
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">
              2
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink href="#">
              3
            </PaginationLink>
          </PaginationItem>
          
          <PaginationItem>
            <PaginationLink href="#">
              4
            </PaginationLink>
          </PaginationItem>
          <PaginationItem>
            <PaginationLink next href="#" />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last href="#" />
          </PaginationItem>
        </Pagination>
      </Row> */}

    </Container>
  );
};

export default BlogPost;