import React from 'react'
import {
  Container,
  Row,
  Col
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle'

function DevelopmentService(props){
  return(
    <Container fluid className="py-5" id="our_work">
      <Container>
        <Row>
          <Col lg="12">
            <SectionTitle title="What we Offer" color="black" />
            <p>We are a full-stack iOS, Android and Web Development company with all the expertise you need to build a successful, scalable and stable product. With our clear approach and step by step process, we deliver beyond expectations. We pursue excellence together and deliver experiences that are rapidly changing</p>
          </Col>
        </Row>        
      </Container>
    </Container>
  );
}

export default DevelopmentService;