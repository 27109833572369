import React from 'react';
import { Link } from 'react-scroll'
import { 
	Container,
	Row,
  Col,
  Button
} from 'reactstrap';
import SectionTitle from '../../components/sectionTitle';

function VacancyDetail(props){
  return (
    <Container className="text-left vacancy-detail py-5">
        <Row className="mb-5">
          <Col md="4">
            <SectionTitle title={["Who are we ", <span className="highlight" key="1">looking for?</span>]} color="black"/>
          </Col>
        </Row>
        <Row>
          <Col md="4">
            <h3>Key Responsibilities</h3>
          </Col>
          <Col md="8">
            <ul className="mb-4 mt-md-2 custom-bullets">
              {props.keyResponsibility}
            </ul>
          </Col>
        </Row>
        
        <Row className="mt-4">
          <Col md="4">
            <h3>Desired Skills and Experience</h3>
          </Col>
          <Col md="8">
            <ul className="mb-4 mt-md-2 custom-bullets">
              {props.skills}
            </ul>
          </Col>
        </Row>

        <Row>
          <Col className="text-center mt-4">
            <Link to="vacancy-form" smooth={true} offset={-70} duration={500}>
              <Button color="black" size="lg">Apply Now</Button>
            </Link>
          </Col>
        </Row>
    </Container>
  );
}

export default VacancyDetail;