import React from 'react';

import {
  CardImg,
} from 'reactstrap';

import ToolsTitle, {ToolsText} from './toolsData'

export default (props) => {
  return(
    <div>
      <CardImg
        src={ require('../../assets/images/career/vacancies/'+props.image) }
      />
      <ToolsTitle title={props.title} />
      <ToolsText title={props.description} />
    </div>
  );
}