import React from 'react'
import{
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'
import {NavLink} from 'react-router-dom'

import SectionTitle from './sectionTitle'

import BlogSubject, {Category} from './blogComponents'
function Blog(){
  return(
    <Container fluid className="py-5 bg-light">
      <Container className="mb-5 text-center">
        <SectionTitle title="Fresh Ideas" />
      </Container>
      <div className="mw-large mx-auto px-3">
        <Row>
          <Col lg="4" className="blog-box px-3 px-lg-5 py-4 py-lg-5 mb-4 mb-lg-0">
            {/*<BlogData title="May 14, 2020" />*/}
            <BlogSubject link="/business/e-commerce-in-covid-19" title="E commerce in COVID 19" />
            <Category title="Business" link="/business" />
          </Col>
          <Col lg="4" className="blog-box px-3 px-lg-5 py-4 py-lg-5 mb-4 mb-lg-0">
            {/*<BlogData title="April 17, 2020" />*/}
            <BlogSubject link="/productivity/stay-focused-in-a-world-full-of-distractions" title="Stay focused in a world full of distractions" />
            <Category title="Productivity" link="/productivity" />
          </Col>
          <Col lg="4" className="blog-box px-3 px-lg-5 py-4 py-lg-5 mb-4 mb-lg-0">
            {/*<BlogData title="April 25, 2020" />*/}
            <BlogSubject link="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important" title="What is a Wireframe? Why is wireframing so important?" />
            <Category title="UI/UX" link="/ui-ux" />
          </Col>
        </Row>
        <Row className="mx-0">
          <Col className="text-center px-0 mt-4">
            <NavLink to="/blog">
              <Button color="black" size="lg">Read More</Button>
            </NavLink>
          </Col>
        </Row>
      </div>
    </Container>
  );
}
export default Blog;