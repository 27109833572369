import React from 'react';

import { Container, Row, Col } from 'reactstrap';
import UIUXPosts from '../../pages/blogs/blogCategory';
import SectionTitle from '../../components/sectionTitle';

function UIUX(){
  return(
    <div>
      <Container fluid className="bg-light">
        <Container className="pt-105">
          <Row>
            <Col md="12">
              <SectionTitle title="UI/UX" color="black" />
            </Col>
          </Row>

          <Row>
            <Col md="12" className="py-5">
              <UIUXPosts category="ui-ux" />
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
}
export default UIUX;