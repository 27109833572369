import React, {Component} from 'react';

import {
	Container,
	Row,
	Col,
	Button,
	Form,
	FormGroup,
  Input,
  Alert
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle';


export default class VacancyForm extends Component {

  constructor(props){
    super(props);
    this.state = {
		  user: {
		    name: "",
		    email: "",
		    phone: "",
		    about_you: "",
		    degree: "",
		    college: "",
		    experience: "",
		    address: "",
		    additional_info: "",
				vacancy: this.props.vacancy
		  },
		  message: "",
		  color: "",
		  email_error: "",
		  name_error: "",
		  phone_error: "",
		  aboutYou_error: "",
		  degree_error: "",
		  college_error: "",
		  experience_error: "",
		  address_error: "",
      additional_info_error: "",
      form_progress: false
		}
  }


  updateUser = (event) => {
    const {name, value} = event.target;
    this.setState({
      user: {
      ...this.state.user,
      [name]: value
      }
    }, function(){
      this.customCheckFormValidation(name);
    })
  }

  customCheckFormValidation = (name) => {
    let name_error = "";
    let phone_error = "";
    let email_error = "";
    let aboutYou_error = "";
    let degree_error = "";
    let college_error = "";
    let experience_error = "";
    let address_error = "";
    let additional_info_error = "";

    if (name === "name"){
      if (this.state.user.name === ""){
        name_error = "Name can't be blank!"
      }
      this.setState({
        name_error
      });
    }else if (name === "email") {
      if (this.state.user.email === ""){
        email_error = "Email can't be blank!"
      }else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.user.email)))
      {
        email_error = "Invalid email!"
      }
      this.setState({
        email_error
      });
    }else if (name === "phone") {
      if (this.state.user.phone === ""){
        phone_error = "Phone number can't be blank!"
      }else if (isNaN(this.state.user.phone)) {
        phone_error = "Phone should be Numeric"
      }else if (this.state.user.phone.length < 10){
        phone_error = "Phone number length is small."
      }
      this.setState({
        phone_error
      });
    }else if(name === "about_you"){
      if(this.state.user.about_you === ""){
        aboutYou_error = "Please tell something about You!"
      }
      this.setState({
        aboutYou_error
      });
    }else if(name === "degree"){
      if(this.state.user.degree === ""){
        degree_error = "Degree can't be black"
      }
      this.setState({
        degree_error
      });
    }else if(name === "college"){
      if(this.state.user.college === ""){
        college_error = "College can't be black"
      }
      this.setState({
        college_error
      });
    }else if(name === "experience"){
      if(this.state.user.experience === ""){
        experience_error = "Experience can't be black"
      }
      this.setState({
        experience_error
      });
    }else if(name === "address"){
      if(this.state.user.address === ""){
        address_error = "Address can't be black"
      }
      this.setState({
        address_error
      });
    }else if(name === "additional_info"){
      if(this.state.user.additional_info === ""){
        additional_info_error = "Please provide additional info!"
      }
      this.setState({
        additional_info_error
      });
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    let formValid = this.checkFormValidation();
    console.log(formValid);

    if (formValid){
      this.setProgress();
      this.submitForm();
    }
  }

  setProgress = () => {
    this.setState({
      message: "In Progress..Please Wait!!",
      color: "primary",
      form_progress: true
    })
  }

  submitForm = () => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + "/candidate"
  	fetch(url ,{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
        "Accept": "application/vnd.cspl_backend.v1",
				"Access-Control-Allow-Origin": "*",
				"Access-Control-Allow-Credentials": "*",
				"Access-Control-Expose-Headers": "*",
				"Access-Control-Max-Age": "*",
				"Access-Control-Allow-Methods": "*",
				"Access-Control-Allow-Headers": "*",
			},
			body: JSON.stringify({candidate: this.state.user}),
		}).then(res => res.json())
    .then((result) => {
      if (result.status === 200) {
        window.location.href = '/thankyou_for_applying' 
      }else if(result.status === 401){
        this.setState({message: result.message,
        color: "danger",
      });
      this.resetState();   
      }
      else {
        this.setState({message: result.message,
          color: "danger"});
        this.resetState(); 
      }
      this.clearMessageTimeout = setTimeout(() => {
        this.setState(() => ({message: ""}))
      }, 3000);
		}, (error) => {
		});
  }

  resetState(){
    this.setState({
      user: {
        name: "",
        email: "",
        phone: "",
        about_you: "",
        degree: "",
        college: "",
        experience: "",
        address: "",
        additional_info: ""
      },
      form_progress: false
    })
  }

  componentWillUnmount() {
    clearTimeout(this.clearMessageTimeout);
  }

  checkFormValidation  = () => {
    let email_error = "";
    let name_error = "";
    let phone_error = "";
    let aboutYou_error = "";
    let degree_error = "";
    let college_error = "";
    let experience_error = "";
    let address_error = "";
    let additional_info_error = "";


    if (this.state.user.email === ""){
      email_error = "Email can't be blank!"
    }else if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.user.email)))
    {
      email_error = "Invalid email!"
    }
    if (this.state.user.name === ""){
      name_error = "Name can't be blank!"
    }
    if (this.state.user.phone === ""){
      phone_error = "Phone number can't be blank!"
    }else if (isNaN(this.state.user.phone)) {
      phone_error = "Phone should be Numeric"
    }else if (this.state.user.phone.length < 10){
      phone_error = "Phone number length is small."
    }else if (this.state.user.phone.length > 10) {
      phone_error = "Phone number length is too large."
    }
    if (this.state.user.about_you === ""){
      aboutYou_error = "Please tell something about You!"
    }
    if (this.state.user.degree === ""){
      degree_error = "Degree can't be black"
    }
    if (this.state.user.college === ""){
      college_error = "College can't be black"
    }
    if (this.state.user.experience === ""){
      experience_error = "Experience can't be black"
    }
    if (this.state.user.address === ""){
      address_error = "Address can't be black"
    }
    if (this.state.user.additional_info === ""){
      additional_info_error = "Please provide additional info!"
    }


    this.setState({
      email_error,
      name_error,
      phone_error,
      aboutYou_error,
      degree_error,
      college_error,
      experience_error,
      address_error,
      additional_info_error
    })

    if(name_error !== "" || email_error !== "" || phone_error !== "" || aboutYou_error !== "" || degree_error !== "" || college_error !== "" || experience_error !== "" || address_error !== "" || additional_info_error !== ""){
      return false
    }else{
      return true
    }
  }

  checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
      ? event.which
      : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }

  addErrorMessage = (msg) => {
		if (msg === ""){
			return ;
		}else{
			return (<span className="error-class"> {msg} </span>);
		}
  }

  render(){
  return(
    <Form id="vacancy">
      <div>
      {
        this.state.message ? <Alert color={this.state.color}>{this.state.message}</Alert> : null
      }
    </div>
      <Input type="hidden" id="job_role" name="job_role" value={this.props.vacancy} />

      <Container fluid className="light-bg py-5">
        <Container className="text-left">
          <SectionTitle title="Apply for this position" />

          <Row className="mt-5">
            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="name" name="name" value={this.state.user.name} onChange={this.updateUser} placeholder="Name" autoComplete="off"/>
                {this.addErrorMessage(this.state.name_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="email" id="email" name="email" value={this.state.user.email} onChange={this.updateUser} placeholder="Email" autoComplete="off" />
                {this.addErrorMessage(this.state.email_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="tel" id="phone" name="phone" value={this.state.user.phone} onChange={this.updateUser} placeholder="Phone" autoComplete="off" maxLength="10" onKeyPress={this.checkNumeric}/>
                {this.addErrorMessage(this.state.phone_error)}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="textarea" id="aboutYou" name="about_you" value={this.state.user.about_you} onChange={this.updateUser} autoComplete="off" placeholder="Describe Yourself" />
                {this.addErrorMessage(this.state.aboutYou_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="text" id="degree" name="degree" value={this.state.user.degree} onChange={this.updateUser} autoComplete="off" placeholder="Highest Degree" />
                {this.addErrorMessage(this.state.degree_error)}
              </FormGroup>
            </Col>

            <Col md="6">
              <FormGroup className="form-field">
                <Input type="text" id="college" name="college" value={this.state.user.college} onChange={this.updateUser} autoComplete="off" placeholder="Name of College" />
                {this.addErrorMessage(this.state.college_error)}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="experience" name="experience" value={this.state.user.experience} onChange={this.updateUser} autoComplete="off" placeholder="Total Experience" />
                {this.addErrorMessage(this.state.experience_error)}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="text" id="address" name="address" value={this.state.user.address} onChange={this.updateUser} autoComplete="off" placeholder="Current Address" />
                {this.addErrorMessage(this.state.address_error)}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="form-field">
                <Input type="textarea" id="additionalInfo" name="additional_info" value={this.state.user.additional_info} onChange={this.updateUser} autoComplete="off" placeholder="please provide any additional information that could help us"/>
              </FormGroup>
              {this.addErrorMessage(this.state.additional_info_error)}
            </Col>
          </Row>

          <Row className="text-center">
            <Col>
              <Button color="black" size="lg" className="mt-4" onClick={this.submitHandler} disabled={this.state.form_progress} >Done. Send my application</Button>
            </Col>
          </Row>
        </Container>
      </Container>
    </Form>
  );
}
}
