import React from 'react';

import PageBanner from '../../components/pageBanner';
import {CareerBannerBG} from '../../components/assets';

import CareerContents from './careerContents';
import JobVacancy from './jobVacancy';
import StayinTouch from './stayinTouch';

function Career(props){
  return(
      <div>
        <PageBanner 
          bannerImage={CareerBannerBG} 
          bannerImageAlt="Careers @Complitech" 
          title={<>Let's make exciting solutions <span className="highlight green" key="1">Together</span></>}
          description="Join A Team That Celebrates You Daily! We are always looking for the next generation of innovators."
        />
        <CareerContents />
        <JobVacancy />
        <StayinTouch />
      </div>
    );
  }
  export default Career;