import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {nearbuyImage} from '../../components/assets'

const Aosa = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>

	    	<Row>
	    		<Col md="8">
	    			<img src={nearbuyImage} alt="NearBuyNZ" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="NearBuyNZ" />
		    			<WorkPostText className="mt-2 mb-3" title="Nearbuy was born from a passion to help local businesses compete with the big multinationals to attract new customers and fill empty spaces in a cost effective way, while increasing patronage, atmosphere, and profitability. We are a home grown kiwi startup that wants to make it easy for kiwis to support their local businesses and help them thrive without the big fees charged by other platforms in the market currently. By using Nearbuy, you can help do your bit to keep your locals in business and find new exciting experiences while saving money for yourself." />
		    			<a href="http://nearbuy.complitech.us/" target="blank">
                <Button color="black" size="lg" className="mb-3">Check out Nearbuy</Button>
              </a>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default Aosa;