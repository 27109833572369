import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import SectionTitle from '../../components/sectionTitle'

import ProjectTitle from '../../components/projectTitle'

import {TroubleShootersBG, EachOtherBG, HungryBG} from '../../components/assets'

function readytoRock(props){
  return(
    <Container fluid className="py-5 bg-light">
      <Container>
        <Row>
          <Col className="mb-5">
            <SectionTitle title="Ready to rock together?" color="black" />
            <p>Just like you, we are committed. We seek partners that are passionate, communicative and  straightforward. We expect you to challenge us - and we’ll do likewise - so that together we can achieve the noteworthy.</p>
          </Col>
        </Row>

        <Row>
          <Col lg="4">
            <div className="media">
              <div className="BigText">1</div>
              <div className="media-body">
                <ProjectTitle title={["WE’RE" , <br key="1"/> , "TROUBLESHOOTERS"]} />
                <p>We are proud to be problem solvers. We like the people we work with that can be - coworkers, managers, clients - and genuinely enjoy helping one another succeed.</p>
                <img src={TroubleShootersBG} alt="Trouble Shooters" className="img-fluid mb-5" />
              </div>
            </div>
          </Col>

          <Col lg="4">
            <div className="media">
              <div className="BigText">2</div>
              <div className="media-body">
                <ProjectTitle title={["WE’RE HERE" , <br key="2"/> ,  "FOR EACH OTHER"]} />
                <p>Above everything, we're a team. That means we show up for each other every single day. We're growing this company not for own personal gain, but for each other.</p>
                <img src={EachOtherBG} alt="We're here for Each Other" className="img-fluid mb-5" />
              </div>
            </div>
          </Col>

          <Col lg="4">
            <div className="media">
              <div className="BigText">3</div>
              <div className="media-body">
                <ProjectTitle title={["WE’RE" , <br key="3"/> ,  "HUNGRY"]} />
                <p>We believe in making something better, everyday. We're hungry, we're zealous, and we love sturdy problems and new challenges at Complitech.</p>
                <img src={HungryBG} alt="We're Hungry" className="img-fluid mb-5" />
              </div>
            </div>
          </Col>
        </Row>

      </Container>
    </Container>
  );
}

export default readytoRock;