import React from 'react'

import {ImageTitleLeft, ImageTitleRight} from './imgTitle';

import {EnterpriseSoftwareDevelopment, MobileDevelopment, UIUX, QA, IT, Digital} from '../../components/assets'

function serviceSections(){
  return(
    <div className="py-5">
      <ImageTitleLeft 
        Image={EnterpriseSoftwareDevelopment}
        ImageAlt="Enterprise Software Development"
        title="Enterprise Software Development"
        description="With our disciplined and systematic approach and attention to usability, we extend end-to-end software development services by using the latest web technologies for addressing different project requirements." />

      <ImageTitleRight
        Image={MobileDevelopment}
        ImageAlt="Mobile App Development"
        title="Mobile App Development"
        description="From iPhone to iPad to Apple Watch & from Gingerbread to Android 10, we've made a mark developing several hundred iPhone, iPad & android apps. From iPhone Developers, Android Developers to Cross-platform App Developers, our highly qualified team can help you build and deploy solutions that will give your business boost." />

      <ImageTitleLeft 
        Image={UIUX}
        ImageAlt="UI/UX Design"
        title="UI/UX Design"
        description="We help startups and businesses by building interfaces that are effortless, innate and deeply engaging. We combine our team's depth of experience in design, your profound comprehension of the industry you serve and insights uncovered through the inventions we make." />

      <ImageTitleRight 
        Image={QA}
        ImageAlt="QA & Testing"
        title="QA & Testing"
        description="We offer you a diverse set of software quality assurance services. We leverage the latest tech and standards to help you create high quality - up-to-date softwares within the required timeframe." />

      <ImageTitleLeft 
        Image={IT}
        ImageAlt="IT Consulting"
        title="IT Consulting"
        description="With our IT Consulting service, you can leverage quality ideas that we can provide to introduce IT in to your business. We can also offer a consultation for your existing IT implementation and suggest modifications you need to leverage it for your business." />

      <ImageTitleRight 
        Image={Digital}
        ImageAlt="Digital Marketing"
        title="Digital Marketing"
        description="We strongly believe that there’s no merit in a ‘one-that-fits-all’ approach to SEO or SEM. Our digital marketing methods are innovative and always anticipate the intent of your customers and search engines." />
    
    </div>
  );
}

export default serviceSections;