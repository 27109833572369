import React from 'react'

import {
  Container,
  Row,
  Col
} from 'reactstrap';

import Title from './bannerComponents';

import {LargeText} from './projectTitle';

function PageBanner(props){
  return(
    <Container fluid className="banner d-flex align-items-center">
      <Row className="w-100 mx-0">
        <Container className="py-5">
          <Row className="d-flex align-items-center">
            <Col lg={{size:'8', offset:'2'}} xl={{size:'6', offset:'0'}} className="order-xl-last mt-lg-5 mt-xl-0">
              <img src={props.bannerImage} alt={props.bannerImageAlt} className="img-fluid"/>
            </Col>
            
            <Col xl="6" className="text-center text-xl-left mt-5 mt-xl-0">
              {/* <Title title={<>We're creative ninjas, We're <span className="highlight purple" key="1">Complitech</span></>} /> */}
              <Title title={props.title} />
              <LargeText className="mt-4 mb-0" title={props.description} />
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default PageBanner;