import React from 'react'

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap'

import {
  CSMobiprintBG,
  Usageicon01,
  Usageicon02,
  Usageicon03,
  Usageicon04,
  MobiPrintPreview,
  PlatformParse,
  PlatformSwift,
  PlatformPhotoshop,
  PlatformHTML,
  PlatformCrash,
  PlatformFabric,
} from '../../components/assets'

import {NavLink} from 'react-router-dom';

import SectionTitle, { SectionSubTitle } from '../../components/sectionTitle';

import { LargeText } from '../../components/projectTitle';

import GetinTouch from '../../components/getinTouch';

function CSMobiprint() {
  return(
    <Container fluid >
      <Row className="pt-105 bg-white text-center px-4">
        <img src={CSMobiprintBG} alt="Mobiprint" className="img-fluid mx-auto"/>
      </Row>
      
      <Container className="py-5">
        <Row>
          <Col md="12">
            <SectionTitle title="Mobiprint" color="black"/>
            
            <p>Mobiprint is an iPhone and android app, allowing users to take printouts from an iPhone, iPad and An-droid and make the whole process of taking printouts flexible and dynamic. Client's mission was to pro-vide the highest quality print utility at an affordable price with a wide variety of solutions supported. Thus, clients wanted to make an application that allows everyone to take printouts from an iPhone / iPad.</p>

            <p>The supposed application was required to search and connect directly to the Bluetooth printers from the iPhone. The intended application also needed the ability to print PDF files, email attachments, and if pos-sible from Pages etc.</p>
          </Col>

          <Col md="12">
            <div className="p-4 bg-light">
              <h3 className="mb-0">The supposed application was required to search and connect directly to the Bluetooth printers from the iPhone and/or Android. The intended application also needed the ability to print PDF files, email attachments, and if possible from Pages etc. Initially the client contacted us with parse server and data details and gave us some zebra commands and on that basis we support zebra printers, ZPL printing support, image processing, web page print-ing, barcode scanners and enhancements still going on...</h3>
            </div>
          </Col>

          <Col md="12" className="mt-5">
            <SectionTitle title="Our Role" />

            <p>Initially, we started to build a solution for iOS devices as per the client's requirement. We worked on fea-tures requested and fine tuned it to meet the criteria to be an all-in-one print solution.</p>
            
            <p>After successfully running the iOS app in the market for almost two years, now, the client was thinking about an Android App. We started working on an Android app and delivered it to support a variety of An-droid devices. Now, we support zebra printers, ZPL printing support, image processing, web page print-ing, barcode scanners and what not...! </p>

            <p>We designed and developed their WordPress site too!</p>

            <LargeText title="Mobi Print has partnered with the top hardware vendors to integrate the native languages for Mfl bluetooth printing to an actual Mobile printer." />
          </Col>
        </Row>
      </Container>

      <Row className="bg-light py-5">
        <Container>
          <Row>
            <Col md="4" className="order-md-last">
              <img src={MobiPrintPreview} alt="Mobile App Preview" className="img-fluid w-100" />
            </Col>
            
            <Col md="8">
              <SectionTitle title="Mobiprint & Scan" color="black" />
              <SectionSubTitle title="Print PDF's, Variable Labels/Receipts, Native ZPL/CPCL" color="black" className="mt-2" />
              <div className="mt-5">
                <LargeText title="- iOS & Android" />
                <LargeText title="- Driverless printing within your own app" />
                <LargeText title="- Full scanning and printing with enterprise features" />
                <LargeText title="- Enterprise License Capabilities" />
              </div>
            </Col>
          </Row>
        </Container>
      </Row>

      <Container className="py-5">
        <Row>
          <Col md="5" className="bg-black p-4 p-lg-5">
            <SectionTitle title="Challenges" color="white mb-4" />
            <LargeText title="One of the main challenges while building this app was to make the process of taking printouts, more flexible and dynamic. Another major challenge was to allow PDFs to be printed. For an Android app, we had to focus on various OS enabled devices to support as much functionality as the operating system can provide." color="white" />
          </Col>

          <Col md="7" className="bg-light p-4 p-lg-5">
            <SectionTitle title="Implementation" color="black mb-4" />
            <LargeText title="As a result, we developed an application that can inte-grate the native languages for Mfl bluetooth printing to an actual Mobile printer. We have taken the hard work out of implementation and built in the SDK's and devel-opment of mobile manufactures hardware. This allows us to print PDF's, images, data strings and other vari-ables into our app for printing." />

            <LargeText title='Also, it can print receipts/labels to any ios mobile printer, ipad mobile printer, iphone mobile printer...in sizes 2", 3" a 4". ' />
          </Col>
        </Row>
      </Container>

      <Row className="py-5 bg-primary text-center">
        <Container>
          <Row>
            <Col className="mb-5">
              <SectionTitle title="Mobiprint and it's global usage" color="black"/>
            </Col>
          </Row>
          <Row>
            <Col md="3" sm="6" className="mb-4 mb-md-0">
              <img src={Usageicon01} alt="Icon 1" className="img-fluid w-auto" />
              <h3 className="mt-4">764,000</h3>
              <p className="mb-0">Total Devices</p>
            </Col>

            <Col md="3" sm="6" className="mb-4 mb-md-0">
              <img src={Usageicon02} alt="Icon 2" className="img-fluid w-auto" />
              <h3 className="mt-4">1250</h3>
              <p className="mb-0">Total Developer</p>
            </Col>

            <Col md="3" sm="6" className="mb-4 mb-md-0">
              <img src={Usageicon03} alt="Icon 3" className="img-fluid w-auto" />
              <h3 className="mt-4">120</h3>
              <p className="mb-0">Countries</p>
            </Col>

            <Col md="3" sm="6" className="mb-4 mb-md-0">
              <img src={Usageicon04} alt="Icon 4" className="img-fluid w-auto" />
              <h3 className="mt-4">12</h3>
              <p className="mb-0">Total Languages</p>
            </Col>
          </Row>
        </Container>
      </Row>

      <Container className="py-5">
        <SectionTitle title="Key Features" color="black" />
        <Row className="mt-4">
          <Col md="5">
            <ol>
              <li><p className="mb-0">Automated Functions (Auto-Return Et Au-to-Print)</p></li>
              <li><p className="mb-0">iOS/Android Support & iOS8 Print Extension</p></li>
              <li><p className="mb-0">Driverless environment</p></li>
              <li><p className="mb-0">Transfer Print Data from Web services like Chrome & Safari</p></li>
              <li><p className="mb-0">Print PDFs/Images from Native iOS Apps</p></li>
              <li><p className="mb-0">Print Data Passed through a URL</p></li>
              <li><p className="mb-0">Convert and Print a any web page with 1 click</p></li>
              <li><p className="mb-0">Scan/Print Barcodes</p></li>
              <li><p className="mb-0">Manage Printers from Mobile Device</p></li>
              <li><p className="mb-0">View Usage On-The-Go</p></li>
              <li><p className="mb-0">Update Driver Settings from the Cloud </p></li>
            </ol>
          </Col>

          <Col md="7">
            <p>Printer connectivity with bluetooth was very new to us but as we came across printing language and printing commands we were confident to make connectivity with any printing language with differ-ent types of printers available. At this stage our ap-plication received such a monopoly that there are approximately 500000 total devices across 120 countries with 12 total language supports and that is really proud of us.</p>
          </Col>
        </Row>

        <Col md="12" className="text-center px-0">
          <NavLink to="/work#allWork">
            <Button color="black" size="lg">View Portfolio</Button>
          </NavLink>
        </Col>
      </Container>

      <Row className="py-5 text-center bg-light">
        <Col md="12">
          <SectionTitle title="Built With" />
        </Col>
        <Container className="py-5 technologies-used">
          <Row>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformParse} alt="Parse Server" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformSwift} alt="Swift" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformPhotoshop} alt="Photoshop" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformHTML} alt="HTML 5" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformCrash} alt="Crashlytics" className="img-fluid w-auto" /></Col>
            <Col lg="2" md="4" sm="6" className="mb-4 mb-lg-0"><img src={PlatformFabric} alt="Fabric" className="img-fluid w-auto" /></Col>
          </Row>
        </Container>
      </Row>

      <Row>
        <GetinTouch />
      </Row>
      
    </Container>
  );
}

export default CSMobiprint;