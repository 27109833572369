import React, { Component } from 'react'

import{
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  Form,
  FormGroup,
  Input,
  Alert
} from 'reactstrap'

import SectionTitle from './sectionTitle';

import {HireusBannerBG, ModalClose} from './assets'

export default class HireUsModal extends Component{

  constructor(props){
    super(props);
    this.state = {
      message: "",
      color: "",
      client: {
        name: "",
        email: "",
        phone: ""
      },
      email_error: "",
      name_error: "",
      phone_error: "",
      form_progress: false
    }
  }

  componentDidMount(){
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27)
        this.setState({
          modal: false
        });
    }); 
  }

  updateClient = (event) => {
    const {name, value} = event.target;
    this.setState({
      client: {
      ...this.state.client,
      [name]: value
      }
    }, function(){
      this.customCheckFormValidation(name);
    })
  }

  customCheckFormValidation = (name) => {
    let name_error = "";
    let phone_error = "";
    let email_error = "";

    if (name === "name"){
      if (this.state.client.name === ""){
        name_error = "Name can't be blank!"
      }
      this.setState({
        name_error
      });
    }else if (name === "email") {
      if (this.state.client.email === ""){
        email_error = "Email can't be blank!"
      }else if (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.client.email)))
      {
        email_error = "Invalid email!"
      }
      this.setState({
        email_error
      });
    }else if (name === "phone") {
      if (this.state.client.phone === ""){
        phone_error = "Phone number can't be blank!"
      }else if (isNaN(this.state.client.phone)) {
        phone_error = "Phone should be Numeric"
      }else if (this.state.client.phone.length < 10){
        phone_error = "Phone number length is small."
      }
      this.setState({
        phone_error
      });
    }
  }

  submitHandler = (event) => {
    event.preventDefault();
    let formValid = this.checkFormValidation();
    console.log(formValid);
    
    if (formValid){
      this.setProgress();
      this.submitForm();
    }
  }

  setProgress = () => {
    this.setState({
      message: "In Progress..Please Wait!!",
      color: "primary",
      form_progress: true
    })
  }


  submitForm = () => {
    let url = process.env.REACT_APP_BACKEND_BASE_URL + "/lead"
  	fetch(url ,{
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "Accept": "application/vnd.cspl_backend.v1",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Credentials": "*",
            "Access-Control-Expose-Headers": "*",
            "Access-Control-Max-Age": "*",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Headers": "*",
        },
        body: JSON.stringify({lead: this.state.client}),
        }).then(res => res.json())
        .then((result) => { 
        if (result.status === 200) {
          window.location.href = '/thankyou_for_inquiry' 
        }else if(result.status === 401){
          this.setState({message: result.message,
          color: "danger",
        });
        this.resetState();   
        }
        else {
          this.setState({message: result.message,
          color: "danger"});
          this.resetState();
        }
        this.clearMessageTimeout = setTimeout(() => {
            this.setState(() => ({message: "", modal: false}))
        }, 3000);
            }, (error) => {
            });
    }

  resetState(){
    this.setState({
      client: {
        name: "",
        email: "",
        phone: "",
      },
      form_progress: false
    })
  }

  checkFormValidation  = () => {
    let email_error = "";
    let name_error = "";
    let phone_error = "";
  
    if (this.state.client.email === ""){
      email_error = "Email can't be blank!"
    }else if(!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/.test(this.state.client.email)))
    {
      email_error = "Invalid email!"
    }
    if (this.state.client.name === ""){
      name_error = "Name can't be blank!"
    }
    if (this.state.client.phone === ""){
      phone_error = "Phone number can't be blank!"
    }else if (isNaN(this.state.client.phone)) {
      phone_error = "Phone should be Numeric"
    }else if (this.state.client.phone.length < 10){
      phone_error = "Phone number length is small."
    }else if (this.state.client.phone.length > 10) {
      phone_error = "Phone number length is too large."
    }
    
    this.setState({
      email_error,
      name_error,
      phone_error,
    })

    if(name_error !== "" || email_error !== "" || phone_error !== ""){  
      return false
    }else{
      return true
    }
  }

  checkNumeric = (event) => {
    var regex = new RegExp("^[0-9]+$");
    var str = String.fromCharCode(
      !event.charCode
      ? event.which
      : event.charCode);
    if (!regex.test(str)) {
      event.preventDefault();
      return false;
    }
  }

  addErrorMessage = (msg) => {
		if (msg === ""){
			return ;
		}else{
			return (<span className="error-class"> {msg} </span>);
		}
  }

  render(){  
  return(
      <Modal isOpen={this.props.displayModal} className="hireus-modal">
        <ModalBody className="d-flex align-items-center">
          <Container>
            <div className="closeButtonModal" onClick={this.props.parentRef}>
              <img src={ModalClose} alt="testimonial-thumbnail" className="w-100" />
            </div>
            <div>
              {
                this.state.message ? <Alert color={this.state.color}>{this.state.message}</Alert> : null
              }
            </div>
            <Row className="d-flex align-items-center">
              <Col md="5" className="order-md-last">
                <img src={HireusBannerBG} alt="Hire Us" className="img-fluid" />
              </Col>

              <Col md="6" className="text-left">
                <Form>
                  <SectionTitle title={<>Success starts with a step, <span className="highlight yellow" key="1">Let’s do it!</span></>} />
                  <p>We promise to get back to you in 24 hours.</p>
                  <Row>
                    <Col md="12">
                      <FormGroup className="form-field">
                        <Input type="text" id="name" name="name" value={this.state.client.name} placeholder="Name" autoComplete="off" onChange={this.updateClient}/>
                        {this.addErrorMessage(this.state.name_error)}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="form-field">
                        <Input type="email" id="email" name="email" value={this.state.client.email} placeholder="Email" autoComplete="off" onChange={this.updateClient}/>
                        {this.addErrorMessage(this.state.email_error)}
                      </FormGroup>
                    </Col>

                    <Col md="6">
                      <FormGroup className="form-field">
                        <Input type="tel" id="phone" name="phone" value={this.state.client.phone} placeholder="Phone" autoComplete="off" onChange={this.updateClient} maxLength="10" onKeyPress={this.checkNumeric}/>
                        {this.addErrorMessage(this.state.phone_error)}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <Button color="black" size="lg" className="mt-4 w-100" disabled={this.state.form_progress} onClick={this.submitHandler}>Submit</Button>
                    </Col>
                    <Col md="6">
                      <Button color="transparent" className="btn-outline mt-4 w-100" onClick={this.props.parentRef}>Cancel</Button>
                    </Col>
                  </Row>
                </Form>
              </Col>

              <Col md="1"></Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
  );
}
}
