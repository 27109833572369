import React from 'react';

import {
  Container,
  Row,
  Col,
  Button
} from 'reactstrap';

import WorkPostTitle, {WorkPostText} from './workPostTags'

import {NavLink} from 'react-router-dom'

import {invoeaseImage} from '../../components/assets'

const Invoease = (props) => {
  return (
    <Container>
        <Row className="pt-105">
          <Col md="12">
            <NavLink to="/work/#allWork" className="back-arrow-link mt-2">
              <svg className="mr-2 jobBackArrow" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512">
                <g>
                  <g>
                    <path d="M508.875,248.458l-160-160c-4.167-4.167-10.917-4.167-15.083,0c-4.167,4.167-4.167,10.917,0,15.083l141.792,141.792
                      H10.667C4.771,245.333,0,250.104,0,256s4.771,10.667,10.667,10.667h464.917L333.792,408.458c-4.167,4.167-4.167,10.917,0,15.083
                      c2.083,2.083,4.813,3.125,7.542,3.125c2.729,0,5.458-1.042,7.542-3.125l160-160C513.042,259.375,513.042,252.625,508.875,248.458z
                      "/>
                  </g>
                </g>
              </svg>
              See all Work
            </NavLink>
          </Col>
    		</Row>

	    	<Row>
	    		<Col md="8">
	    			<img src={invoeaseImage} alt="Invoease" className="img-fluid border mb-3" />
	    		</Col>
	    		<Col md="4">
	    			<div className="sticky-top">
		    			<WorkPostTitle title="Invoease" />
		    			<WorkPostText className="mt-2 mb-3" title="Invoease has been working with many IT Consulting firms over decade. Since we have many years of track record in helping IT Consulting companies sending and collecting payments from their clients. During this process, we understood how important an employee’s/Consultant’s approved timesheets plays big role on collecting the payments on time from their vendors." />
		    			<WorkPostText className="mt-2 mb-3" title="Most of the time IT Consulting companies employ their HR Staff offshore. HR Staff engages with consultants and follows up weekly to receive the vendor and/or end client’s approved timesheets. It can be very tedious process. Many companies had to go back and forth with their employees and clients in order to send the right amount for the invoices. Sometimes the hours entered were not matching with end client’s timesheets or their employees were too slow sending their approved hours. We have many IT Consulting companies requested to solve this puzzle by automating this process. We can proudly say that we have put together great automated timesheets submitting, tracking and converting those timesheets into invoices in matter of seconds with high level of accuracy." />
		    			<a href="https://invoease.com/" target="blank">
                <Button color="black" size="lg" className="mb-3">Check out Invoease</Button>
              </a>
	    			</div>
	    		</Col>
	    	</Row>
    </Container>
  );
};

export default Invoease;