import React from 'react'

import { Helmet } from 'react-helmet-async';

// Page Progressbar
import PageProgress from 'react-page-progress'

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {Wireframing} from '../../components/assets';

import SectionTitle from '../../components/sectionTitle';

import {BlogPostDate, BlogQuote, PrevBlog, NextBlog} from '../../pages/blogs/blogPostTags';

import Sidebar, {Bottombar} from '../../pages/blogs/sidebar';

import{
  EcommerceInCOVID19,
  StayFocusedInWorldFullOfDistractions
} from '../../pages/blogs/blogList';

function BlogWireframing() {

  return(<>
    <PageProgress color="var(--purple)" height={3} className="page-progress-bar"/>
    <Container fluid className="px-0">
      <Helmet>
        <title>What is a Wireframe? Why is wireframing so important?</title>
        <meta property="og:type" content="article" />
        <meta name="description" content="What is a Wireframe? Why is wireframing so important?" />
      </Helmet>
      <img src={Wireframing} alt="Wireframing" className="img-fluid w-100" />

      <Container className="py-5 our-voice">
        <Row className="position-relative">

          {/* SIDEBAR */}
          <Col lg="3" className="blog-left-sidebar">
            <Sidebar
              categoryLink="/ui-ux"
              categoryName="UI/UX"
              title="What is a Wireframe? Why is wireframing so important?"
            />
          </Col>

          {/* BLOG CONTENT */}
          <Col lg="9">
            <Row>
              <Col>
                <SectionTitle title="What is a Wireframe? Why is wireframing so important?" color="black" />
                <BlogPostDate title="April 25, 2020" />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <p>Wireframing is one of the most important and first steps in your design process. It’s the time when you draw your ideas on a page. Despite the fact that wireframing looks simple, it can be done well or poorly, and this can have a significant impact on the outcome of the final product</p>

                <h3>Wondering about Wireframes?</h3>
                <p>A wireframe is a visual representation of a user interface, stripped off any visual design or branding elements. It is used by UX Designers to define the hierarchy of items on a screen and communicate what the items on that page should be based on user needs. A wireframe is also known as a Page Schematic or Screen Blueprint.</p>

                <p>They are devoid of color, font choices, logos or any real design elements that take away from purely focusing on a site’s structure.</p>

                <BlogQuote size="lg" title="Wireframes are created to communicate, educate, and inform." />

                <h3>When Should Wireframes Be Created?</h3>
                <p>Wireframes should be created before the team starts with visual details. During this phase, the designers are testing the scope of the product, collaborating on ideas, and identifying business requirements. As wireframes are quick and cheap to produce, designers can do experiments with moving objects around, grouping items together, adding or removing elements, etc.</p>

                <h3>No style? No style at all?</h3>
                <p>Yes. The best wireframe style is no style. It’s just monochrome - one color against white.</p>

                <p>You should keep these points in your mind while making them.</p>
                <ul className="custom-bullets">
                  <li><p>They are basic two-dimensional illustrations</p></li>
                  <li><p>They focus on layout, content, and order</p></li>
                  <li><p>They do not include styling, color, or graphics</p></li>
                  <li><p>They should be kept as simple as possible</p></li>
                </ul>

                <BlogQuote size="sm" title="Building a website is a process. Wireframing is one of those parts of the web process that should not be skipped, just as you wouldn’t build a house without a blueprint, or live in it without decoration. Each step has an important place in a larger process." />

                <h3>Why is Wireframing so important?</h3>
                <ul className="custom-bullets">
                  <li>
                    <h3>It can be used as a communication tool</h3>
                    <p>A picture is worth a thousand words. Wireframes clearly communicate design decisions to teammates and partners. They can have a good idea of what screens an app or website will have.</p>
                  </li>

                  <li>
                    <h3>It defines website features</h3>
                    <p>When communicating your ideas with your clients, wireframes can be a very useful tool. Sometimes your clients are not technical people, so they can have a proper idea by seeing wireframes of how they’ll function and what purpose they’ll serve.</p>
                  </li>

                  <li>
                    <h3>It makes design changes more efficient</h3>
                    <p>You can’t expect that every time you make a page or website design and it will be approved immediately without any changes. Whenever clients or your manager want some changes in your design, it takes much less time and effort with wireframing. Wireframes are easy to produce and inexpensive than web page design.</p>
                  </li>

                  <li>
                    <h3>It helps web developers</h3>
                    <p>In my opinion, when you ask developers to develop a page without a wireframe, you’re asking them to do the job with one hand tied behind their back. Wireframes give web developers a clear path of what has to be done. It clearly shows the directions and objectives of the site/app build and allows for better decision making.</p>
                  </li>
                </ul>

                <h3>There’s a difference between Wireframing and Prototyping</h3>
                <p>A lot of people in the field interchange the use of these two terms, ‘wireframe’ and ‘prototype’. But there’s a significant difference between the two: they look different, they communicate different things, and they serve different purposes. Website wireframes are low-fidelity, basic layout and structural guidelines of your web product’s layout and prototypes are an advanced wireframe with more visual detail and interaction.</p>

                <p>So there we have it: Wireframing is not a cure-all, but is something we’ve found to be a very valuable tool. Wireframes save heaps of time and money in the long run!</p>

                <p>Ready to start creating your own wireframes?</p>
                
              </Col>
            </Row>
          </Col>
        
          <Bottombar
            categoryLink="/ui-ux"
            categoryName="UI/UX"
            title="wireframing"
          />
        </Row>
      </Container>

      <Container>
        <Row className="next-prev-blog py-5">
          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'0'}} className="text-center text-lg-left mb-4 mb-lg-0">
            <PrevBlog
              to="/productivity/stay-focused-in-a-world-full-of-distractions"
              title="Stay focused in a world full of distraction"
            />
          </Col>

          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'4'}} className="text-center text-lg-right">
            <NextBlog
              to="/business/e-commerce-in-covid-19"
              title="E-commerce in COVID 19"
            />
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="py-5 bg-light related-posts">
      <Container>
        <SectionTitle title="Related Posts" />

        <Row className="blog-post mt-5">
          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <StayFocusedInWorldFullOfDistractions />
          </Col>

          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <EcommerceInCOVID19 />
          </Col>

        </Row>
      </Container>
    </Container>

  </>);
}

export default BlogWireframing;