import React from 'react'

import { Helmet } from 'react-helmet-async';

// Page Progressbar
import PageProgress from 'react-page-progress'

import {
  Container,
  Row,
  Col,
} from 'reactstrap';

import {StayFocused} from '../../components/assets';

import SectionTitle from '../../components/sectionTitle';

import {BlogPostDate, BlogQuote ,BlogHashTags, PrevBlog, NextBlog} from '../../pages/blogs/blogPostTags';

import Sidebar, {Bottombar} from '../../pages/blogs/sidebar';

import{
  EcommerceInCOVID19,
  WhatIsWireframeWhyIsWireframingSoImportant,
} from '../../pages/blogs/blogList';

function BlogStayFocused() {  
  return(<>
    <PageProgress color="var(--yellow)" height={3} className="page-progress-bar"/>
    <Container fluid className="px-0">
      <Helmet>
        <title>Complitech Blog - Stay focused in a world full of distractions</title>
        <meta property="og:title" content="Stay focused in a world full of distractions" />
        <meta property="og:image" content={StayFocused} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content="In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks. Though working from home is not new for some of them. This can be highly productive, but can also go badly wrong. It's all too easy to get distracted and to get very little done by the end of the day. At home, there are different kinds of distractions, which are not there in a typical workplace." />
        <meta name="description" content="Stay focused in a world full of distractions" />
      </Helmet>

      <img src={StayFocused} alt="Stay Focused" className="img-fluid w-100" />

      <Container className="py-5 our-voice">
        <Row>
          {/* SIDEBAR */}
          <Col lg="3" className="blog-left-sidebar">
            <Sidebar
              categoryLink="/productivity"
              categoryName="Productivity"
              title="Stay focused in a world full of distractions"
            />
          </Col>
          
          {/* BLOG CONTENT */}
          <Col lg="9">
            <Row>
              <Col>
                <SectionTitle title="Stay focused in a world full of distractions" color="black" />
                <BlogPostDate title="April 17, 2020" />
              </Col>
            </Row>

            <Row className="mt-4">
              <Col>
                <p>In this pandemic situation of Coronavirus aka COVID-19, most IT people work from home for the past few weeks. Though working from home is not new for some of them. This can be highly productive, but can also go badly wrong. It's all too easy to get distracted and to get very little done by the end of the day. At home, there are different kinds of distractions, which are not there in a typical workplace.</p>

                <p>Good thing is, building focus is a lot like building physical muscle. It gets stronger when you work on building focus.</p>

                <BlogQuote size="lg" title={["Focus is a matter of deciding what things you’re not going to do.", <span className="author" key="1">John Carmack</span>]} />

                <p>Let’s see some of the ways that you can apply and build a stronger focus in a world full of distractions.</p>

                <h3>Focus on one thing at a time</h3>
                <p>Don’t multitask. Instead, work on one task for a set time before you take a break or switch to another task. Use the Pomodoro method. You might be wondering, what's the Pomodoro Method? This method uses a timer to break down work into intervals, traditionally 25 minutes in length, separated by short breaks.</p>

                <h3>Take regular breaks</h3>
                <p>As we mentioned above about the Pomodoro Method, work intervals should be separated by short breaks. And use these breaks to complete your distraction items, like social media, googling some random questions/movie reviews, quick games, etc.</p>

                <h3>Meditation</h3>
                <p>Practicing meditation regularly for even a few minutes can keep you cool, calm and collected. It increases your attention span. Since focusing the mind is challenging, a beginner might meditate for only a few minutes and then work up to longer durations.</p>
                  
                <h3>Mindfulness</h3>
                <p>During the day, practice mindfulness whenever you can. Mindfulness is the practice of purposely focusing your attention on the present moment - and accepting it without judgment. Observe physical and emotional sensations at the moment. For example, when you eat, experience the flavor, smell and texture of food.</p>

                <h3>Exercise</h3>
                <p>Regular physical activity can improve your muscle strength, boost your endurance and help your cardiovascular system work more efficiently. And when your heart and lung health improve, you have more energy to tackle daily chores. Studies have proven repeatedly that exercising helps us feel better, build strong willpower and be focused.</p>

                <h3>Be curious</h3>
                <p>Curiosity is what keeps us alive, drives us and helps us succeed. When you’re curious about something (not everything), you dig deeper and deeper to know more and It makes your mind active instead of passive. And, that really helps to build stronger focus.</p>

                <h3>Concentration exercises</h3>
                <p>Just like the muscles in your body, your brain needs weekly exercise to tone up the strength of its focus and concentration. There are some concentration exercises, like gazing at your finger or an object, focused breathing exercises (pranayama), using a stress ball, etc.</p>

                <p>Research has proven that people who can focus for a long period perform better at any cognitive task. A scatter-shot focus person can surf through the surface of knowledge, but can’t dive deep to discover the treasure below.</p>

                <BlogHashTags title="#focus" />
                <BlogHashTags title="#focused" />
                <BlogHashTags title="#workfromhome" />
                <BlogHashTags title="#workingfromhome" />
                <BlogHashTags title="#distractions" />
                <BlogHashTags title="#break" />
                <BlogHashTags title="#meditation" />
                <BlogHashTags title="#exercise" />

              </Col>
            </Row>

          </Col>
        
          {/* BOTTOM BAR */}
          <Bottombar 
            categoryLink="/productivity"
            categoryName="Productivity"
            title="Stay focused in a world full of distractions"
          />
        </Row>
      </Container>

      <Container>
        <Row className="next-prev-blog py-5">
          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'0'}} className="text-center text-lg-left mb-4 mb-lg-0">
            <PrevBlog
              to="/ui-ux/what-is-a-wireframe-why-is-wireframing-so-important"
              title="What is a Wireframe? Why is wireframing so important?"
            />
          </Col>

          <Col sm={{size:'8', offset:'2'}} lg={{size:'4', offset:'4'}} className="text-center text-lg-right">
            <NextBlog
              to="/business/e-commerce-in-covid-19"
              title="E-commerce in COVID 19"
            />
          </Col>
        </Row>
      </Container>
    </Container>

    <Container fluid className="bg-light py-5 related-posts">
      <Container>
        <SectionTitle title="Related Posts" />

        <Row className="blog-post mt-5">
          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <WhatIsWireframeWhyIsWireframingSoImportant />
          </Col>

          {/* BLOG */}
          <Col md="6" className="mb-3 mb-md-0">
            <EcommerceInCOVID19 />
          </Col>
        </Row>
      </Container>
    </Container>
  </>);

}

export default BlogStayFocused;