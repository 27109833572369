import React from 'react';

import {
  Container,
  Row,
  Col,
} from 'reactstrap'

import SectionTitle from '../../components/sectionTitle';

import { VacancyLink } from './vacancyComponents'

function JobVacancy() {
  return(
    <Container fluid className="bg-light">
      <Row className="mx-0 pt-0 pt-lg-5 pb-5 vacancy" id="vacancy">
        <Container>
          <Row id="currentOpenings">
            <Col className="mb-3 mb-md-5">
              <SectionTitle title="Current Openings" />
            </Col>
          </Row>

          <Row>        
            <Col sm="12">
              <VacancyLink to="./careers/android-developer" title="Android Developer" icon="androidLogo"/>
            </Col>
          </Row>
        </Container>
      </Row>
    </Container>
  );
}

export default JobVacancy;